import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Collapse, Badge } from 'reactstrap';

import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';

import navigation from "./navigation";
import IsGranted from "../../../../security/IsGranted";
import OneOfGranted from "../../../../security/OneOfGranted";
import HasDepartment from "../../../../security/HasDepartment";
import i18n from "i18next";

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
    <li title={i18n.t('common:' + item.name)} className={isActive ? 'active' : ''}>
        <Link to={'/private' + item.path} onClick={() => ReloadPage(isActive)}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span className={"sidebar-item-label"}><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link>
    </li>
);

const ReloadPage = (isActive) => {
    if (isActive) {
        window.location = '';
    }
};

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
    <li title={i18n.t('common:' + item.name)} className={isActive ? 'active' : ''}>
        <div className="nav-item" onClick={handler}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={isOpen}>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                {children}
            </ul>
        </Collapse>
    </li>
);

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
    <li className="sidebar-subnav-header"><Trans i18nKey={item.name}>{item.name}</Trans></li>
);

class Sidebar extends Component {

    state = {
        collapse: {},
        menu: [],
    };

    componentDidMount() {
        this.setState({
            menu: navigation
        });

        // pass navigator to access router api
        SidebarRun(this.navigator.bind(this));
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList()
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {};
        this.state.menu
            .filter(({ heading }) => !heading)
            .forEach(({ name, path, submenu }) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({ path }) => path) : path)
            });
        this.setState({ collapse });
    };

    navigator(route) {
        this.props.history.push(route);
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.indexOf(this.props.location.pathname) > -1;
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({ path }) => path)

    /** map menu config to string to determine what elemetn to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    };

    render() {
        return (
            <aside className='aside-container'>
                <div className="aside-inner">
                    {this.state.menu && (
                        <nav data-sidebar-anyclick-close="" className="sidebar">
                            <ul className="sidebar-nav">
                                <li className="has-user-block">
                                    <SidebarUserBlock />
                                </li>

                                {
                                    this.state.menu.map((item, i) => {
                                        // heading
                                        if (this.itemType(item) === 'heading')
                                            return (
                                                <OneOfGranted key={i} permissions={item.permissions}>
                                                    <SidebarItemHeader item={item} key={i} />
                                                </OneOfGranted>
                                            )
                                        else {
                                            if (this.itemType(item) === 'menu')
                                                return (
                                                    <HasDepartment allowedDepartment={item.department ? item.department : null}>
                                                        <OneOfGranted key={i}
                                                            permissions={item.permissions}>
                                                            <SidebarItem isActive={this.routeActive(item.path)} item={item}
                                                                key={i} />
                                                        </OneOfGranted>
                                                    </HasDepartment>
                                                )
                                            if (this.itemType(item) === 'submenu')
                                                return [
                                                    <OneOfGranted key={i} permissions={item.permissions}>
                                                        <SidebarSubItem item={item}
                                                            isOpen={this.state.collapse[item.name]}
                                                            handler={this.toggleItemCollapse.bind(this, item.name)}
                                                            isActive={this.routeActive(this.getSubRoutes(item))}
                                                            key={i}>
                                                            <SidebarSubHeader item={item} key={i} />
                                                            {
                                                                item.submenu.map((subitem, i) =>
                                                                    <OneOfGranted key={i}
                                                                        permissions={subitem.permissions}>
                                                                        <SidebarItem key={i}
                                                                            item={subitem}
                                                                            isActive={this.routeActive(subitem.path)} />
                                                                    </OneOfGranted>
                                                                )
                                                            }
                                                        </SidebarSubItem>
                                                    </OneOfGranted>
                                                ]
                                        }
                                        return null;
                                    })
                                }
                            </ul>
                        </nav>
                    )}
                </div>
            </aside>
        )
    }
}

export default withRouter(Sidebar);
