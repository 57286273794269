import React, { Component } from 'react';
import elogo from "../../../../images/el.svg";

class Footer extends Component {

    render() {
        const year = new Date().getFullYear();

        return (
            <footer className="footer-container">
                <div className="row">
                    <div className="col-12 col-sm-8 hhhis-text text-center text-sm-left">
                        <span>&copy; {year} - Hooldus-, heakorra- ja haljastustööde infosüsteem</span>
                    </div>
                    <div className="col-12 col-sm-4 text-center text-sm-right">
                        <img src={elogo} style={{ height: '91px', paddingRight: '20px' }} alt="" />
                    </div>
                </div>
            </footer>
        );
    }

}

export default Footer;
