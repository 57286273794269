import React from 'react';

class IsGranted extends React.Component {

    handleParsePermissions = () => {
        let encoded = localStorage.getItem('permissions');
        return atob(encoded).split('|');
    };

    handleCheckPermission = (permissions) => {
        if (permissions) {
            permissions = Array.isArray(permissions) ? permissions : [permissions];
            let remains = permissions.length,
                granted = this.handleParsePermissions();
            for (let permission of permissions) {
                if (granted.indexOf(permission) > -1) {
                    remains--;
                }
            }
            if (remains === 0) {
                return (<>{this.props.children}</>)
            } else {
                if (this.props.redirect) {
                    return window.location.href = this.props.redirect;
                } else if (this.props.ifDenied) {
                    return <>{this.props.ifDenied}</>;
                } else {
                    return null;
                }
            }
        }

        return null;
    };

    render() {
        const {permissions} = this.props;
        return this.handleCheckPermission(permissions);
    }
}

export default IsGranted;
