import axios from 'axios';
import {getAuthUrl} from "../utils";

const token = localStorage.getItem('user_token');

export const client = axios.create({
    baseURL: process.env.REACT_APP_API_V2,
    timeout: 90000,
    headers: {
        common: {
            'Authorization': token ? `Bearer ${token}` : ""
        },
        post: {
            'Content-Type': 'application/json'
        }
    }
})

client.interceptors.response.use(function (response) {
    if (response.headers['x-token']) {
        localStorage.setItem('user_token', response.headers['x-token']);
    }

    if (response.headers['current-time']) {
        localStorage.setItem('currentTime', response.headers['current-time']);
    }

    return response;
}, function (error) {

    if (process.env.NODE_ENV === 'development') {
        if(error.message) {
            console.log("ERROR MESSAGE: " + error.message);
        }

        if(error.response && error.response.status) {
            console.log("STATUS CODE: " + error.response.status);
        }
    }

    //Need for login
    if(error.response !== undefined && error.response.status === 401){
        localStorage.setItem("redirect_after_login", window.location);
        window.location = getAuthUrl();
    }

    return Promise.reject(error);
})

export const saveToken = (payload) => {
    document.cookie = "BEARER=" + payload.token + "; path=/;";
    localStorage.setItem("user_token", payload.token);
    setToken(payload.token);
}

export const setToken = (token) => {
    client.defaults.headers = {
        'Authorization': token ? `Bearer ${token}` : "",
        post: {
            'Content-Type': 'application/json'
        }
    }
}
