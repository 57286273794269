import {ADD_MESSAGE, REMOVE_MESSAGE} from "../../constants/action-types";

const initialState = {
    messages: []
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MESSAGE:
            return {...state, messages: [...state.messages, action.payload]};
        case REMOVE_MESSAGE:
            state.messages.shift();
            return state;
        default:
            return state;
    }
};

export default messagesReducer;
