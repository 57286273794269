import React, {Component} from 'react';

import {FieldConnect, ErrorField} from 'react-components-form';

export class TextareaField extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange({target: {value}}) {
        const {trim, onChange} = this.props;

        onChange(trim && value ? value.trim() : value);

        if (typeof this.props.onChanged === "function") {
            this.props.onChanged(value);
        }
    }

    render() {
        const {
            name,
            validationErrors,
            hasValidationError,
            value,
            label,
            required,
            placeholder,
            disabled,
            errorStyles,
            readOnly,
            fieldAttributes,
        } = this.props;

        let wrapperClassNames = hasValidationError ? 'form-group is-error' : 'form-group';
        if(fieldAttributes && fieldAttributes.hidden){
            wrapperClassNames += ' d-none';
        }

        return (
            <div className={wrapperClassNames}>
                {label && (
                    <label htmlFor={name}>
                        {label}
                        {required && <span className="text-danger"> *</span>}
                    </label>
                )}
                <textarea
                    name={name}
                    onChange={this.handleChange}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={hasValidationError ? 'form-control is-invalid' : 'form-control'}
                    readOnly={readOnly}
                    {...fieldAttributes}
                />

                {hasValidationError &&
                <small className="text-form text-danger">
                    <ErrorField errors={validationErrors} {...errorStyles} />
                </small>}
            </div>
        );
    }
}

export default FieldConnect(TextareaField);
