import React from 'react';
import PropTypes from 'prop-types';

import {FieldConnect, ErrorField} from 'react-components-form';

export class RadioField extends React.Component {
    renderOptionsList() {
        const {
            options,
            placeholder,
            fieldAttributes,
            value,
            path,
            onChange,
            onChangeData,
            inline
        } = this.props;

        return options.map((option, index) => {
            const uniqueName = `${path}-${index}`;
            const optionValue = option.label ? option.value : option;
            const onClick = () => {
                onChange(optionValue);

                if (onChangeData) {
                    onChangeData(optionValue);
                }
            };
            return (
                <div key={uniqueName} className={inline ? "form-check form-check-inline" : "form-check"}>
                    <input
                        type="radio"
                        name={path}
                        placeholder={placeholder}
                        className="form-check-input"
                        checked={value === optionValue}
                        value={optionValue}
                        htmlFor={uniqueName}
                        id={uniqueName}
                        onChange={onClick}
                        disabled={option.disabled}
                        {...fieldAttributes}
                    />
                    <label className="form-check-label" htmlFor={uniqueName}>
                        {option.label || option}
                    </label>
                </div>
            );
        });
    }

    render() {
        const {
            name,
            validationErrors,
            hasValidationError,
            label,
            errorStyles,
            required
        } = this.props;


        return (
            <div className="form-radio-buttons">
                <div htmlFor={name}>{label}</div>
                {this.renderOptionsList()}
                {(required && !hasValidationError) ? (
                    <div className="text-danger">Selle välja täitmine on kohustuslik.</div>
                ) : (
                    <div/>
                )}
                {hasValidationError &&
                <div className="text-danger"><ErrorField errors={validationErrors} {...errorStyles} /></div>}
            </div>
        );
    }
}

RadioField.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.shape({}),
        PropTypes.func,
    ]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.shape({}),
        PropTypes.func,
    ]),
};

RadioField.defaultProps = {
    value: '',
    options: [],
};

export default FieldConnect(RadioField);
