import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';
import './Tabs.scss';

export default class Tabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.title,
        };
    }

    onClickTabItem = (tab) => {
        this.setState({
            activeTab: tab,
        });
    };

    render() {
        const {
            onClickTabItem,
            props: {
                children,
            },
            state: {
                activeTab,
            }
        } = this;

        return (
            <div className="tabs">
                <ul className="tab">
                    {children.map((child) => {
                        const {title} = child.props;

                        return (
                            <Tab
                                activeTab={activeTab}
                                key={title}
                                label={title}
                                onClick={onClickTabItem}
                            />
                        );
                    })}
                </ul>
                <div className="tab-content">
                    {children.map((child) => {
                        if (child.props.title !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        )
    }
}

Tabs.propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
};