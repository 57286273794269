const navigation = [
    {
        heading: 'Main',
        translate: 'sidebar.main',
    },
    {
        name: 'sidebar.dashboard',
        icon: 'icon-speedometer',
        translate: 'sidebar.dashboard',
        path: '/dashboard',
    },
    {
        name: 'sidebar.problems',
        icon: 'icon-directions',
        translate: 'sidebar.problems',
        path: '/problems',
        permissions: ['CAN_LIST_PROBLEMS'],
    },
    {
        name: 'sidebar.citizenReports',
        icon: 'icon-envelope-letter',
        translate: 'sidebar.citizenReports',
        path: '/citizen',
        permissions: ['CAN_LIST_PROBLEMS'],
    },
    {
        name: 'sidebar.plannedRepairs',
        icon: 'icon-wrench',
        translate: 'sidebar.plannedRepairs',
        path: '/planned_repairs',
        permissions: ['CAN_LIST_PLANNED_REPAIRS'],
    },
    {
        name: 'sidebar.objects',
        icon: 'icon-notebook',
        translate: 'sidebar.objects',
        permissions: [
            'CAN_LIST_OBJECTS',
            'CAN_LIST_OBJECT_ELEMENTS',
            'CAN_LIST_OBJECT_SPARES',
        ],
        submenu: [
            {
                name: 'sidebar.objects',
                translate: 'sidebar.objects',
                path: '/objects',
                permissions: ['CAN_LIST_OBJECTS'],
            },
            {
                name: 'sidebar.objectsElements',
                translate: 'sidebar.objectsElements',
                path: '/objects_elements',
                permissions: ['CAN_LIST_OBJECT_ELEMENTS'],
            },
            {
                name: 'sidebar.objectsSpares',
                translate: 'sidebar.objectsSpares',
                path: '/objects_spares',
                permissions: ['CAN_LIST_OBJECT_SPARES'],
            }
        ]
    },
    {
        name: 'sidebar.maintenance',
        icon: 'icon-clock',
        translate: 'sidebar.maintenance',
        permissions: ['CAN_LIST_MAINTENANCE', 'CAN_READ_GPS_TRACKING_VEHICLES', 'CAN_READ_DEPARTMENT_STREET_MAINTENANCE',
            'CAN_READ_COMPANY_STREET_MAINTENANCE_WINTER', 'CAN_READ_COMPANY_STREET_MAINTENANCE_SPRING_SUMMER',
            'CAN_READ_DEPARTMENT_SNOW_REMOVAL_REQUESTS', 'CAN_READ_COMPANY_SNOW_REMOVAL_REQUESTS',
            'CAN_READ_DEPARTMENT_SNOW_REMOVAL_REPORTS', 'CAN_READ_COMPANY_SNOW_REMOVAL_REPORTS'],
        submenu: [
            {
                name: 'sidebar.maintenance',
                translate: 'sidebar.maintenance',
                path: '/maintenance',
                permissions: ['CAN_LIST_MAINTENANCE'],
            },
            {
                name: 'header.treeMaintenances',
                translate: 'header.treeMaintenances',
                path: '/tree_evaluations',
                permissions: ['CAN_LIST_TREE_EVALUATIONS', 'CAN_LIST_TREE_EVALUATIONS_PARTNER'],
            },
            {
                name: 'sidebar.vehicles',
                translate: 'sidebar.vehicles',
                path: '/maintenance_vehicles',
                permissions: ['CAN_READ_GPS_TRACKING_VEHICLES']
            },
            {
                name: 'sidebar.winterStreetMaintenance',
                translate: 'sidebar.winterStreetMaintenance',
                path: '/maintenance_street_winter',
                permissions: ['CAN_READ_DEPARTMENT_STREET_MAINTENANCE_WINTER', 'CAN_READ_COMPANY_STREET_MAINTENANCE_WINTER']
            },
            {
                name: 'sidebar.springSummerStreetMaintenance',
                translate: 'sidebar.springSummerStreetMaintenance',
                path: '/maintenance_street_spring_summer',
                permissions: ['CAN_READ_DEPARTMENT_STREET_MAINTENANCE_SPRING_SUMMER', 'CAN_READ_COMPANY_STREET_MAINTENANCE_SPRING_SUMMER']
            },
            {
                name: 'sidebar.snowRemovalRequests',
                translate: 'sidebar.snowRemovalRequests',
                path: '/snow_removal_requests',
                permissions: ['CAN_READ_DEPARTMENT_SNOW_REMOVAL_REQUESTS', 'CAN_READ_COMPANY_SNOW_REMOVAL_REQUESTS']
            },
            {
                name: 'sidebar.snowRemovalReports',
                translate: 'sidebar.snowRemovalReports',
                path: '/snow_removal_reports',
                permissions: ['CAN_READ_DEPARTMENT_SNOW_REMOVAL_REPORTS', 'CAN_READ_COMPANY_SNOW_REMOVAL_REPORTS']
            }
        ]
    },
    {
        name: 'sidebar.penaltyProtocols',
        icon: 'icon-ban',
        translate: 'sidebar.penaltyProtocols',
        path: '/penalty_protocol',
        department: ['Tallinna Keskkonna- ja Kommunaalamet'],
        permissions: ['CAN_VIEW_LIST_PENALTIES'],
    },
    {
        name: 'sidebar.maintenancePlans',
        icon: 'icon-event',
        translate: 'sidebar.maintenancePlans',
        permissions: ['CAN_VIEW_LIST_MAINTENANCE_PLAN','CAN_VIEW_PLANNED_MAINTENANCE'],
        submenu: [
            {
                name: 'sidebar.maintenancePlans',
                translate: 'sidebar.maintenancePlans',
                path: '/maintenance_plan',
                permissions: ['CAN_VIEW_LIST_MAINTENANCE_PLAN'],
            },
            {
                name: 'sidebar.plannedMaintenance',
                translate: 'sidebar.plannedMaintenance',
                path: '/planned_maintenance',
                permissions: ['CAN_VIEW_LIST_MAINTENANCE_PLAN','CAN_VIEW_PLANNED_MAINTENANCE'],
            },
        ]
    },
    {
        name: 'sidebar.priceOffers',
        icon: 'icon-calculator',
        translate: 'sidebar.priceOffers',
        path: '/object_price_offers',
        permissions: ['CAN_LIST_OBJECT_PRICE_OFFERS'],
    },
    {
        name: 'sidebar.breakdowns',
        icon: 'icon-energy',
        translate: 'sidebar.breakdowns',
        path: '/breakdowns',
        permissions: ['CAN_VIEW_LIST_BREAKDOWNS'],
    },
    {
        name: 'sidebar.jobs',
        icon: 'icon-wrench',
        translate: 'sidebar.jobs',
        path: '/jobs',
        permissions: ['CAN_LIST_JOBS'],
    },
    {
        name: 'sidebar.acts',
        icon: 'icon-clock',
        translate: 'sidebar.acts',
        path: '/acts',
        permissions: [
            'CAN_VIEW_LIST_ACTS',
        ],
    },
    {
        name: 'sidebar.contracts',
        icon: 'icon-paper-clip',
        translate: 'sidebar.contracts',
        path: '/contracts',
        permissions: ['CAN_LIST_CONTRACTS'],
    },
    {
        name: 'sidebar.tenders',
        icon: 'icon-folder',
        translate: 'sidebar.tenders',
        path: '/tenders',
        permissions: ['CAN_LIST_TENDERS'],
    },
    {
        name: 'header.reports',
        icon: 'icon-chart',
        translate: 'header.reports',
        permissions: [
            'CAN_READ_REPORT_MAINTENANCE',
            'CAN_READ_REPORT_REGISTERED_JOBS',
            'CAN_READ_REPORT_ACCEPTED_PRICE_OFFERS_JOBS_AS_DEPARTMENT',
            'CAN_READ_REPORT_ACCEPTED_PRICE_OFFERS_JOBS_AS_PARTNER',
            'CAN_READ_REPORT_FINANCIAL',
            'CAN_READ_REPORT_FINANCIAL_CITY_PARTS'
        ],
        submenu: [
            {
                name: 'reports.financialReport',
                translate: 'reports.financialReport',
                path: '/reports/financial',
                permissions: ['CAN_READ_REPORT_FINANCIAL'],
            },
            {
                name: 'reports.financialCityPartsReport',
                translate: 'reports.financialCityPartsReport',
                path: '/reports/financial_city_parts',
                permissions: ['CAN_READ_REPORT_FINANCIAL_CITY_PARTS'],
            },
            {
                name: 'reports.maintenanceReport',
                translate: 'reports.maintenanceReport',
                path: '/reports/maintenance',
                permissions: ['CAN_READ_REPORT_MAINTENANCE'],
            },
            {
                name: 'reports.registeredJobsReport',
                translate: 'reports.registeredJobsReport',
                path: '/reports/registered_jobs',
                permissions: ['CAN_READ_REPORT_REGISTERED_JOBS'],
            },
            {
                name: 'reports.acceptedJobsReport',
                translate: 'reports.acceptedJobsReport',
                path: '/reports/accepted_price_offers_jobs',
                permissions: ['CAN_READ_REPORT_ACCEPTED_PRICE_OFFERS_JOBS_AS_DEPARTMENT', 'CAN_READ_REPORT_ACCEPTED_PRICE_OFFERS_JOBS_AS_PARTNER'],
            }
        ]
    },
    // {
    //     heading: 'sidebar.references',
    //     translate: 'sidebar.references',
    //     permissions: ['CAN_VIEW_LIST_ACT_STATUSES', 'CAN_VIEW_LIST_ACT_REJECTION_REASONS'],
    // },
    {
        name: 'sidebar.acts',
        icon: 'icon-target',
        translate: 'sidebar.acts',
        permissions: ['CAN_VIEW_LIST_ACT_STATUSES', 'CAN_VIEW_LIST_ACT_REJECTION_REASONS'],
        submenu: [
            {
                name: 'sidebar.actStatuses',
                translate: 'sidebar.actStatuses',
                path: '/act_statuses',
                permissions: ['CAN_VIEW_LIST_ACT_STATUSES']
            },
            {
                name: 'sidebar.actRejectionReasons',
                translate: 'sidebar.actRejectionReasons',
                path: '/act_rejection_reasons',
                permissions: ['CAN_VIEW_LIST_ACT_REJECTION_REASONS']
            }
        ]
    },
    {
        name: 'sidebar.contracts',
        icon: 'icon-doc',
        translate: 'sidebar.contracts',
        permissions: [
            'CAN_VIEW_LIST_CONTRACT_TYPES',
            'CAN_VIEW_LIST_CONTRACT_STATUSES',
            'CAN_VIEW_LIST_CONTRACT_SUBTYPES',
            'CAN_VIEW_LIST_CONTRACT_BILLING_PERIOD_STATUSES'
        ],
        submenu: [
            {
                name: 'sidebar.contractsTypes',
                translate: 'sidebar.contractsTypes',
                path: '/contract_types',
                permissions: ['CAN_VIEW_LIST_CONTRACT_TYPES'],
            },
            {
                name: 'sidebar.contractStatuses',
                translate: 'sidebar.contractStatuses',
                path: '/contract_statuses',
                permissions: ['CAN_VIEW_LIST_CONTRACT_STATUSES'],
            },
            {
                name: 'sidebar.contractSubtype',
                translate: 'sidebar.contractSubtypes',
                path: '/contract_subtypes',
                permissions: ['CAN_VIEW_LIST_CONTRACT_SUBTYPES'],
            },
            {
                name: 'sidebar.contractBillingPeriodStatuses',
                translate: 'sidebar.contractBillingPeriodStatuses',
                path: '/contract_billing_period_statuses',
                permissions: ['CAN_VIEW_LIST_CONTRACT_BILLING_PERIOD_STATUSES'],
            }
        ]
    },
    {
        name: 'sidebar.priceOffers',
        icon: 'icon-wallet',
        translate: 'sidebar.priceOffers',
        permissions: ['CAN_VIEW_LIST_PRICE_OFFER_STATUSES', 'CAN_LIST_PRICE_OFFER_REJECTION_REASONS'],
        submenu: [
            {
                name: 'sidebar.priceOffersStatuses',
                translate: 'sidebar.priceOffersStatuses',
                path: '/price_offers_statuses',
                permissions: ['CAN_VIEW_LIST_PRICE_OFFER_STATUSES'],
            },
            {
                name: 'sidebar.priceOffersRejectionReasons',
                translate: 'sidebar.priceOffersRejectionReasons',
                path: '/price_offers_rejection_reasons',
                permissions: ['CAN_LIST_PRICE_OFFER_REJECTION_REASONS'],
            }
        ]
    },
    {
        name: 'sidebar.files',
        icon: 'icon-folder',
        translate: 'sidebar.files',
        permissions: ['CAN_VIEW_LIST_FILE_TYPES', 'CAN_VIEW_LIST_FILE_GROUPS'],
        submenu: [
            {
                name: 'sidebar.fileGroups',
                translate: 'sidebar.fileGroups',
                path: '/file_groups',
                permissions: ['CAN_VIEW_LIST_FILE_GROUPS'],
            },
            {
                name: 'sidebar.fileTypes',
                translate: 'sidebar.fileTypes',
                path: '/file_types',
                permissions: ['CAN_VIEW_LIST_FILE_TYPES'],
            }
        ]
    },
    {
        name: 'sidebar.jobs',
        icon: 'icon-directions',
        translate: 'sidebar.jobs',
        permissions: [
            'CAN_VIEW_LIST_JOB_REJECTION_REASONS',
            'CAN_VIEW_LIST_JOB_TYPES',
            'CAN_VIEW_LIST_JOB_STATUSES',
            'CAN_VIEW_LIST_REPAIR_TYPES',
            'CAN_VIEW_LIST_PROBLEM_TYPES'
        ],
        submenu: [
            {
                name: 'sidebar.jobRejectionReasons',
                translate: 'sidebar.jobRejectionReasons',
                path: '/job_rejection_reasons',
                permissions: ['CAN_VIEW_LIST_JOB_REJECTION_REASONS'],
            },
            {
                name: 'sidebar.jobTypes',
                translate: 'sidebar.jobTypes',
                path: '/job_types',
                permissions: ['CAN_VIEW_LIST_JOB_TYPES'],
            },
            {
                name: 'sidebar.jobTypeGroups',
                translate: 'sidebar.jobTypeGroups',
                path: '/job_type_groups',
                permissions: ['CAN_VIEW_LIST_JOB_TYPE_GROUPS'],
            },
            {
                name: 'sidebar.jobStatuses',
                translate: 'sidebar.jobStatuses',
                path: '/job_statuses',
                permissions: ['CAN_VIEW_LIST_JOB_STATUSES'],
            },
            {
                name: 'sidebar.repairTypes',
                translate: 'sidebar.repairTypes',
                path: '/repair_types',
                permissions: ['CAN_VIEW_LIST_REPAIR_TYPES'],
            },
            {
                name: 'sidebar.repairStatuses',
                translate: 'sidebar.repairStatuses',
                path: '/repair_statuses',
                permissions: ['CAN_VIEW_LIST_REPAIR_STATUSES'],
            },
        ]
    },
    {
        name: 'sidebar.maintenance',
        icon: 'icon-directions',
        translate: 'sidebar.maintenance',
        permissions: ['CAN_VIEW_LIST_MAINTENANCE_REJECTION_REASONS'],
        submenu: [
            {
                name: 'sidebar.maintenanceRejectionReasons',
                translate: 'sidebar.maintenanceRejectionReasons',
                path: '/maintenance_rejection_reasons',
                permissions: ['CAN_VIEW_LIST_MAINTENANCE_REJECTION_REASONS'],
            }
        ]
    },
    {
        name: 'sidebar.departments',
        icon: 'icon-people',
        translate: 'sidebar.departments',
        permissions: ['CAN_VIEW_LIST_DEPARTMENTS', 'CAN_VIEW_LIST_DIVISIONS'],
        submenu: [
            {
                name: 'sidebar.departments',
                translate: 'sidebar.departments',
                path: '/departments',
                permissions: ['CAN_VIEW_LIST_DEPARTMENTS'],
            },
            {
                name: 'sidebar.divisions',
                translate: 'sidebar.divisions',
                path: '/divisions',
                permissions: ['CAN_VIEW_LIST_DIVISIONS'],
            }
        ]
    },
    {
        name: 'sidebar.services',
        icon: 'icon-layers',
        translate: 'sidebar.services',
        permissions: ['CAN_VIEW_LIST_SERVICES', 'CAN_VIEW_LIST_SERVICE_GROUPS'],
        submenu: [
            {
                name: 'sidebar.services',
                translate: 'sidebar.services',
                path: '/services',
                permissions: ['CAN_VIEW_LIST_SERVICES'],
            },
            {
                name: 'sidebar.serviceGroups',
                translate: 'sidebar.serviceGroups',
                path: '/service_groups',
                permissions: ['CAN_VIEW_LIST_SERVICE_GROUPS'],
            }
        ]
    },
    {
        name: 'sidebar.persons',
        icon: 'icon-people',
        translate: 'sidebar.persons',
        permissions: ['CAN_VIEW_LIST_PERSONS', 'CAN_VIEW_LIST_ROLES'],
        submenu: [
            {
                name: 'sidebar.persons',
                translate: 'sidebar.persons',
                path: '/persons',
                permissions: ['CAN_VIEW_LIST_PERSONS'],
            },
            {
                name: 'sidebar.roles',
                translate: 'sidebar.roles',
                path: '/roles',
                permissions: ['CAN_VIEW_LIST_ROLES'],
            }
        ]
    },
    {
        name: 'sidebar.problems',
        icon: 'icon-bulb',
        translate: 'sidebar.problems',
        permissions: [
            'CAN_VIEW_LIST_PROBLEM_TYPES',
            'CAN_VIEW_LIST_PROBLEM_TYPE_GROUPS',
            'CAN_VIEW_LIST_PROBLEM_STATUSES',
            'CAN_VIEW_LIST_PROBLEM_STATES',
            'CAN_VIEW_LIST_ROAD_SIGNS',
            'CAN_VIEW_LIST_PROBLEM_TYPES_WORKFLOW'
        ],
        submenu: [
            {
                name: 'sidebar.problemTypes',
                translate: 'sidebar.problemTypes',
                path: '/problem_types',
                permissions: ['CAN_VIEW_LIST_PROBLEM_TYPES'],
            },
            {
                name: 'sidebar.problemTypeGroups',
                translate: 'sidebar.problemTypeGroups',
                path: '/problem_type_groups',
                permissions: ['CAN_VIEW_LIST_PROBLEM_TYPE_GROUPS'],
            },
            {
                name: 'sidebar.problemStatuses',
                translate: 'sidebar.problemStatuses',
                path: '/problem_statuses',
                permissions: ['CAN_VIEW_LIST_PROBLEM_STATUSES'],
            },
            {
                name: 'sidebar.problemStates',
                translate: 'sidebar.problemStates',
                path: '/problem_states',
                permissions: ['CAN_VIEW_LIST_PROBLEM_STATES'],
            },
            {
                name: 'sidebar.roadSigns',
                translate: 'sidebar.roadSigns',
                path: '/road_signs',
                permissions: ['CAN_VIEW_LIST_ROAD_SIGNS'],
            },
            {
                name: 'sidebar.problemTypesWorkflow',
                translate: 'sidebar.problemTypesWorkflow',
                path: '/problem_types_workflow',
                permissions: ['CAN_VIEW_LIST_PROBLEM_TYPES_WORKFLOW'],
            }
        ]
    },
    {
        name: 'sidebar.normatives',
        icon: 'icon-note',
        translate: 'sidebar.normatives',
        permissions: [
            'CAN_VIEW_LIST_MEASURE_UNITS',
            'CAN_VIEW_LIST_NORMATIVE_GROUPS',
            'CAN_VIEW_LIST_NORMATIVE',
            'CAN_VIEW_LIST_QUALITIES'
        ],
        submenu: [
            {
                name: 'sidebar.measureUnits',
                translate: 'sidebar.measureUnits',
                path: '/measure_units',
                permissions: ['CAN_VIEW_LIST_MEASURE_UNITS'],
            },
            {
                name: 'sidebar.normativeGroups',
                translate: 'sidebar.normativeGroups',
                path: '/normative_groups',
                permissions: ['CAN_VIEW_LIST_NORMATIVE_GROUPS'],
            },
            {
                name: 'sidebar.normatives',
                translate: 'sidebar.normatives',
                path: '/normatives',
                permissions: ['CAN_VIEW_LIST_NORMATIVE'],
            },
            {
                name: 'sidebar.qualities',
                translate: 'sidebar.qualities',
                path: '/qualities',
                permissions: ['CAN_VIEW_LIST_QUALITIES'],
            }
        ]
    },
    {
        name: 'sidebar.tasks',
        icon: 'icon-notebook',
        translate: 'sidebar.tasks',
        path: '/tasks',
        permissions: [
            'CAN_LIST_OWN_TASKS'
        ]
    },
    {
        name: 'sidebar.objects',
        icon: 'icon-bulb',
        translate: 'sidebar.objects',
        permissions: [
            'CAN_VIEW_LIST_OBJECT_OWNERS',
            'CAN_VIEW_LIST_OBJECT_TYPES',
            'CAN_VIEW_LIST_OBJECT_STATUSES',
            'CAN_VIEW_LIST_OBJECT_CUSTOM_FIELDS',
            'CAN_VIEW_LIST_OBJECT_ELEMENT_GROUPS',
        ],
        submenu: [
            {
                name: 'header.objectOwners',
                translate: 'header.objectOwners',
                path: '/object_owners',
                permissions: ['CAN_VIEW_LIST_OBJECT_OWNERS'],
            },
            {
                name: 'header.objectTypes',
                translate: 'header.objectTypes',
                path: '/object_types',
                permissions: ['CAN_VIEW_LIST_OBJECT_TYPES'],
            },
            {
                name: 'header.objectStatuses',
                translate: 'header.objectStatuses',
                path: '/object_statuses',
                permissions: ['CAN_VIEW_LIST_OBJECT_STATUSES'],
            },
            {
                name: 'header.attributes',
                translate: 'header.attributes',
                path: '/object_types_custom_fields',
                permissions: ['CAN_VIEW_LIST_OBJECT_CUSTOM_FIELDS'],
            },
            {
                name: 'header.treeNames',
                translate: 'header.treeNames',
                path: '/tree_names',
                permissions: ['CAN_VIEW_LIST_TREE_NAMES'],
            },
            {
                name: 'header.treeSorts',
                translate: 'header.treeSorts',
                path: '/tree_sorts',
                permissions: ['CAN_VIEW_LIST_TREE_SORTS'],
            },
            {
                name: 'header.objectElementGroups',
                translate: 'header.objectElementGroups',
                path: '/object_element_groups',
                permissions: ['CAN_VIEW_LIST_OBJECT_ELEMENT_GROUPS'],
            }
        ]
    },
    {
        name: 'sidebar.busstops',
        icon: 'icon-bulb',
        translate: 'sidebar.busstops',
        path: '/busstops',
        permissions: ['CAN_IMPORT_BUSSTOPS'],
    },
    {
        name: 'sidebar.tenders',
        icon: 'icon-folder-alt',
        translate: 'sidebar.tenders',
        permissions: ['CAN_VIEW_LIST_TENDER_STATUSES', 'CAN_VIEW_LIST_TENDER_DISTRICTS', 'CAN_VIEW_LIST_TENDER_STATUSES_WORKFLOW'],
        submenu: [
            {
                name: 'sidebar.tenderStatuses',
                translate: 'sidebar.tenderStatuses',
                path: '/tender_statuses',
                permissions: ['CAN_VIEW_LIST_TENDER_STATUSES']
            },
            {
                name: 'sidebar.tenderDistricts',
                translate: 'sidebar.tenderDistricts',
                path: '/tender_districts',
                permissions: ['CAN_VIEW_LIST_TENDER_DISTRICTS']
            },
            {
                name: 'sidebar.tenderDecisions',
                translate: 'sidebar.tenderDecisions',
                path: '/tender_decisions',
                permissions: ['CAN_VIEW_LIST_TENDER_DECISIONS']
            },
            {
                name: 'sidebar.tenderStatusesWorkflow',
                translate: 'sidebar.tenderStatusesWorkflow',
                path: '/tender_statuses_workflow',
                permissions: ['CAN_VIEW_LIST_TENDER_STATUSES_WORKFLOW']
            },
            {
                name: 'header.tenderComplianceWithRequirements',
                translate: 'header.tenderComplianceWithRequirements',
                path: '/tender_compliance_with_requirements',
                permissions: ['CAN_VIEW_LIST_TENDER_COMPLIANCE_WITH_REQUIREMENTS']
            }
        ]
    },
    {
        name: 'sidebar.addresses',
        icon: 'icon-home',
        translate: 'sidebar.addresses',
        permissions: ['CAN_VIEW_LIST_CITY_PARTS'],
        submenu: [
            {
                name: 'sidebar.cityParts',
                translate: 'sidebar.cityParts',
                path: '/city_parts',
                permissions: ['CAN_VIEW_LIST_CITY_PARTS'],
            },
            {
                name: 'sidebar.serviceDistricts',
                translate: 'sidebar.serviceDistricts',
                path: '/service_districts',
                permissions: ['CAN_VIEW_LIST_SERVICE_DISTRICTS'],
            }
        ]
    },
    {
        name: 'sidebar.products',
        icon: 'icon-tag',
        translate: 'sidebar.products',
        permissions: ['CAN_VIEW_LIST_PRODUCT_STATUSES'],
        submenu: [
            {
                name: 'sidebar.productStatuses',
                translate: 'sidebar.productStatuses',
                path: '/product_statuses',
                permissions: ['CAN_VIEW_LIST_PRODUCT_STATUSES'],
            }
        ]
    },
    {
        name: 'sidebar.maps',
        icon: 'icon-map',
        translate: 'sidebar.maps',
        permissions: ['CAN_VIEW_LIST_MAP_LAYERS'],
        submenu: [
            {
                name: 'sidebar.mapLayers',
                translate: 'sidebar.mapLayers',
                path: '/map_layers',
                permissions: ['CAN_VIEW_LIST_MAP_LAYERS'],
            },
        ]
    },
    {
        name: 'sidebar.serviceIntensity',
        icon: 'icon-clock',
        translate: 'sidebar.serviceIntensity',
        path: '/service_intensity',
        permissions: ['CAN_VIEW_LIST_SERVICE_INTENSITY'],
    },
    {
        heading: 'sidebar.options',
        translate: 'sidebar.options',
        permissions: ['CAN_LIST_PARTNER_EMPLOYEES', 'CAN_LIST_DEPARTMENT_EMPLOYEES', 'CAN_LIST_DEPARTMENT_DEPUTY',
            'CAN_LIST_PARTNERS', 'CAN_LIST_CONTRACT_TEMPLATES', 'CAN_LIST_GPS_TRACKING_SETTINGS','CAN_READ_OWN_PARTNER_EMPLOYEES'],
    },
    {
        name: 'sidebar.employees',
        icon: 'icon-people',
        translate: 'sidebar.employees',
        path: '/employees',
        permissions: ['CAN_LIST_PARTNER_EMPLOYEES','CAN_READ_OWN_PARTNER_EMPLOYEES'],
    },
    {
        name: 'sidebar.employees',
        icon: 'icon-people',
        translate: 'sidebar.employees',
        path: '/department/employees',
        permissions: ['CAN_LIST_DEPARTMENT_EMPLOYEES'],
    },
    {
        name: 'sidebar.deputy',
        icon: 'icon-user-following',
        translate: 'sidebar.deputy',
        path: '/deputy',
        permissions: ['CAN_LIST_DEPARTMENT_DEPUTY'],
    },
    {
        name: 'sidebar.partners',
        icon: 'icon-people',
        translate: 'sidebar.partners',
        path: '/partners',
        permissions: ['CAN_LIST_PARTNERS'],
    },
    {
        name: 'sidebar.myCompanies',
        icon: 'icon-people',
        translate: 'sidebar.myCompanies',
        path: '/my_companies',
        permissions: ['CAN_LIST_MY_COMPANIES'],
    },
    {
        name: 'sidebar.contractTemplates',
        icon: 'icon-user-following',
        translate: 'sidebar.contractTemplates',
        path: '/contract_templates',
        permissions: ['CAN_LIST_CONTRACT_TEMPLATES'],
    },
    {
        name: 'sidebar.gpsTrackingSettings',
        icon: 'icon-location-pin',
        translate: 'sidebar.gpsTrackingSettings',
        path: '/gps_tracking_settings',
        permissions: ['CAN_LIST_GPS_TRACKING_SETTINGS'],
    },
    {
        name: 'sidebar.apiTokens',
        icon: 'icon-key',
        translate: 'sidebar.apiTokens',
        path: '/apitokens',
        permissions: ['CAN_READ_API_TOKENS'],
    },
    {
        name: 'sidebar.openDataSettings',
        icon: 'icon-settings',
        translate: 'sidebar.openDataSettings',
        path: '/open_data_settings',
        permissions: ['CAN_LIST_OPEN_DATA_SETTINGS'],
    },
    {
        name: 'sidebar.supportEmailSettings',
        icon: 'icon-settings',
        translate: 'sidebar.supportEmailSettings',
        path: '/supportEmail',
        permissions: ['CAN_UPDATE_SYSTEM_SETTINGS'],
    },
    {
        name: 'sidebar.logs',
        icon: 'icon-list',
        translate: 'sidebar.logs',
        path: '/logs',
        permissions: ['CAN_LIST_LOGS'],
    }
];

export default navigation;
