import React from 'react';
import classnames from 'classnames';

import Select, { components, createFilter } from 'react-select'

import PropTypes from 'prop-types';
import { FieldConnect, ErrorField } from 'react-components-form';
import i18n from 'i18next';

const stringify = option => option.label;

const optimizeLength = 100; //Optimize select component if options count is more than this value

class MultiSelectField extends React.Component {
    beforeChanged = (val, e, callback) => {
        this.props.beforeChanged(val, e, callback);
    };

    handleOnChange(val, e, isMulti = Select.defaultProps.isMulti, name = "") {
        //console.log('handleOnChange');
        if (!isMulti && this.props.onChange && Array.isArray(val) && val.length === 0) {
            // fix react-select returning [] on clear (see: https://github.com/JedWatson/react-select/issues/2682)
            val = {};
        }
        if (this.props.beforeChanged) {
            this.beforeChanged(val, e, () => this.props.onChange(val));

            if (this.props.onChanged) {
                this.props.onChanged(val, e, name);
            }
        } else {
            this.props.onChange(val);

            if (this.props.onChanged) {
                this.props.onChanged(val, e, name);
            }
        }
    }

    render() {
        const {
            name,
            className,
            errorStyles,
            label,
            placeholder,
            isMulti,
            attributes,
            options,
            wrapperClassName,
            hasValidationError,
            required,
            maxErrorsCount,
            disabled,
            closeMenuOnSelect
        } = this.props;

        let {
            defaultValue,
            value,
            validationErrors,
            colourStyles,
            isColored
        } = this.props;

        const fieldWrapperClassName = classnames(
            wrapperClassName,
            hasValidationError && errorStyles,
        );

        colourStyles = colourStyles ? colourStyles : {
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: data.color,
            }),
            multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: data.color,
                ':hover': {
                    backgroundColor: data.color,
                    color: 'white',
                },
            }),
            //Option with props isActive = false -> greyed
            option: (styles, { data }) => {
                return {
                    ...styles,
                    color: data.isActive === false
                        ? '#ccc'
                        : data.color
                };
            }
        };

        //If empty object -> null (prevent selected empty value (were placeholder and required validator problems))
        if (value && value.constructor === Object && Object.keys(value).length === 0) {
            value = null;
        }
        if (defaultValue && defaultValue.constructor === Object && Object.keys(defaultValue).length === 0) {
            defaultValue = null;
        }

        //Replace validation error field name, bcs validator don't know field name
        if (validationErrors.length > 0) {
            for (let errorIdx in validationErrors) {
                validationErrors[errorIdx] = validationErrors[errorIdx].replace('{{}}', label);

                if (!maxErrorsCount) {
                    //Get only last error, bsc user don't need a lot of errors on same time and first error mb type error, if value is null
                    validationErrors = validationErrors[validationErrors.length - 1];
                    break;
                }
                //Remove odd errors
                else if (maxErrorsCount <= errorIdx) {
                    validationErrors.splice(errorIdx, 1);
                }
            }
        }

        const filterOption = createFilter({
            ignoreCase: true,
            stringify,
            trim: true,
            matchFrom: 'any',
            ignoreAccents: (options && !(options.length > optimizeLength))
        });

        const componentList = {};

        if (options && options.length > optimizeLength) {
            componentList.MenuList = props => {
                const children = props.children;

                if (!children.length) {
                    return (<div className="myClassListName">{children}</div>);
                }

                return (
                    <div className="myClassListName">
                        {children.length && children.map((key, i) => {
                            if (i > optimizeLength) return false; //Hide other options (user can prompt option name to filter this list)
                            delete key.props.innerProps.onMouseMove; //FIX LAG!!
                            delete key.props.innerProps.onMouseOver;  //FIX LAG!!

                            return (
                                <div className="myClassItemName" key={i}>{key}</div>
                            );
                        })}
                    </div>
                );
            };
        }

        if (isColored) {
            componentList.SingleValue = ({ children, ...props }) => (
                <components.SingleValue {...props}>
                    {props && props.data && props.data.hasOwnProperty('colorSample') &&
                        <i className='fa fa-circle m-1' style={{ color: props.data.colorSample }} />
                    }
                    {children}
                </components.SingleValue>
            );

            componentList.MultiValueLabel = props => (
                <components.MultiValueLabel>
                    {props && props.data && props.data.hasOwnProperty('colorSample') &&
                        <i className='fa fa-circle m-1' style={{ color: props.data.colorSample }} />
                    }
                    {props.data.label}
                </components.MultiValueLabel>
            );

            componentList.Option = props => (
                <components.Option {...props}>
                    {props && props.data && props.data.hasOwnProperty('colorSample') &&
                        <i className='fa fa-circle m-1' style={{ color: props.data.colorSample }} />
                    }
                    {props.data.label}
                </components.Option>
            );
        }

        return (
            <div className={fieldWrapperClassName}>
                {label && (
                    <label htmlFor={name}>
                        {label}
                        {required && <span className="text-danger"> *</span>}
                    </label>
                )}
                <Select
                    components={componentList}
                    closeMenuOnSelect={closeMenuOnSelect ? closeMenuOnSelect : !isMulti}
                    className={className}
                    options={options}
                    styles={colourStyles}
                    value={value}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isMulti={isMulti}
                    noOptionsMessage={() => i18n.t("common:fields.noOptions")}
                    defaultValue={defaultValue}
                    onChange={(val, e) => this.handleOnChange(val, e, isMulti, name)}
                    filterOption={filterOption}
                    isOptionDisabled={(option) => option.isActive === false}
                    matchProp={"label"}
                    {...attributes}
                />
                <small className="text-form text-danger">
                    {hasValidationError && <ErrorField errors={validationErrors} {...errorStyles} />}
                </small>
            </div>
        )
    }
}

MultiSelectField.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
};

export default FieldConnect(MultiSelectField);
