import React from 'react';
import {withNamespaces} from 'react-i18next';

import PropTypes from 'prop-types';

import {FieldConnect, ErrorField} from 'react-components-form';
import {fieldDefaultProps, fieldDefaultPropTypes} from "../constants";

import Datetime from 'react-datetime';
import 'moment/locale/et';
// import 'react-datetime/css/react-datetime.css';

import "./DateField.scss";
import moment from "moment-timezone";

class DateField extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.isFirstChange = true;
    }

    handleChange(date) {
        const {onChange, onChangeValue, dateFormat} = this.props;

        if (date !== null && this.handleValidateDate(date)) {
            if (this.isFirstChange && this.props.time && this.props.defaultTime && moment(date).format('HH:mm') === '00:00') {
                date = moment(date).set({
                    'hour': this.props.defaultTime.hour || 0,
                    'minute': this.props.defaultTime.minute || 0,
                });
            }
            if (dateFormat){
                onChange(moment(date).format(this.props.time ? dateFormat + ' HH:mm' : dateFormat));
            } else {
                onChange(moment(date).format(this.props.time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'));
            }
        } else {
            onChange(date);
        }

        if (typeof onChangeValue === 'function') {
            onChangeValue(typeof date === 'string' ? date : (this.props.time ? date.format('DD.MM.YYYY HH:mm') : date.format('DD.MM.YYYY')));
        }

        this.isFirstChange = false;
    }

    handleValidateDate = (date) => {
       if (typeof date === 'string') {
           const dateReg = /^\d{2}([./-])\d{2}\1\d{4}$/;
           const dateRegTime = /^(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2})$/;

           if (this.props.time) {
               return date.match(dateRegTime);
           } else {
               return date.match(dateReg);
           }
       } else {
           return true;
       }
    };

    render() {
        const {
            name,
            validationErrors,
            hasValidationError,
            value,
            label,
            errorStyles,
            placeholder,
            required,
            time,
            dateFormat,
            disabled,
            isValidDate,
            customClassName
        } = this.props;

        let className = hasValidationError ? 'is-invalid ': '';
        if(customClassName){
            className += customClassName;
        }

        return (
            <div className="form-group">
                {label && <label htmlFor={name}>
                    {label}
                    {required && <span className="text-danger"> *</span>}
                </label>}
                <Datetime
                    onChange={this.handleChange}
                    dateFormat={dateFormat ? dateFormat : 'DD.MM.YYYY'}
                    timeFormat={!!time}
                    value={value}
                    readOnly={true}
                    className={className}
                    closeOnSelect={true}
                    inputProps = {{
                        placeholder: placeholder,
                        disabled: disabled || false,
                    }}
                    isValidDate={isValidDate}
                />
                {hasValidationError &&
                <small className="text-form text-danger">
                    <ErrorField errors={validationErrors} {...errorStyles} />
                </small>}
            </div>
        )
    }
}

DateField.propTypes = {
    ...fieldDefaultPropTypes,
    value: PropTypes.any,
};

DateField.defaultProps = {
    ...fieldDefaultProps,
    wrapperClassName: 'form-group',
    className: 'form-control',
};

export default FieldConnect(withNamespaces('common')(DateField));
