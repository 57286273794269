import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import {withNamespaces} from 'react-i18next';

import './UpdateNotificator.scss';

const URL = process.env.REACT_APP_WS_HOST;

class UpdateNotificator extends Component {

    state = {
        timer: null,
        updateProcess: false
    };

    componentDidMount() {
    }

    logout = () => {
        // JWT Cookie need for file-storage server to validate permissions
        document.cookie = "BEARER=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        localStorage.removeItem("user_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("refresh_expired_at");

        return null;
    };

    render() {

        const {t} = this.props;

        return (
            <Fragment>
                {this.state.updateProcess &&
                <>
                    <div className="update-holder"></div>
                    <div className="update-message">
                        <div className="text-center text-danger">
                            <h4>
                                <br/>
                                {t('messages.updateSystem', {time: this.state.timer})}
                            </h4>
                        </div>
                    </div>
                </>
                }
            </Fragment>
        )
    }
}

UpdateNotificator.propTypes = {
    client: PropTypes.object
};

UpdateNotificator.defaultProps = {
    client: {}
};

export default withNamespaces('common')(UpdateNotificator);