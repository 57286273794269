import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-i18next";


import API from '../../apiV2/auth';

import { addMessage } from "../../store/actions";

import "./Authorization.scss";
import { saveToken, setToken } from "../../apiV2/client";
import queryString from "query-string";

const mapDispatchToProps = { addMessage };


export class ConnectedTaraCallback extends Component {
    redirectAfterFailure = () => {
        if (localStorage.getItem('env') === 'dev') {
            this.props.addMessage({ type: "warning", message: 'ENV -> no redirect' });
            return;
        }

        this.props.history.push('/');
    };

    componentDidMount() {
        const parsedURLParams = queryString.parse(this.props.location.search);
        const { code, state, error, error_description } = parsedURLParams;
        if (state !== process.env.REACT_APP_AUTH_URL_STATE) {
            this.props.addMessage({ type: "error", message: "bad_state" });
            this.redirectAfterFailure();
        } else if (error) {
            this.props.addMessage({ type: "error", message: error_description });
            this.redirectAfterFailure();
        } else {
            API.taraAuth(code).then(response => {
                if (!response.status) {
                    this.props.addMessage({ type: "error", message: response.message });
                    this.redirectAfterFailure();
                } else {
                    saveToken(response);
                    setToken(response.token);
                    //const token = localStorage.getItem("user_token");
                    this.props.history.push('/auth?id=1&changeRole=true&data=' + response.token);
                }
            });
        }
    }

    render() {
        return true
    }
}

const TaraCallback = connect(null, mapDispatchToProps)(ConnectedTaraCallback);

export default withRouter(translate('common')(TaraCallback));
