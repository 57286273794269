import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
// import 'sweetalert/dist/sweetalert.css';
import swal from 'sweetalert';

import './Swal.scss';

export const deleteOption = {
    buttons: {
        confirm: {
            text: i18n.t('common:form.yes'),
            value: true,
            visible: true,
            className: "confirm",
            closeModal: true
        },
        cancel: {
            text: i18n.t('common:form.no'),
            value: null,
            visible: true,
            className: "cancel",
            closeModal: true,
        }
    },
    closeOnConfirm: true,
    closeOnCancel: true,
    closeOnClickOutside: false,
    icon: "warning",
    title: i18n.t('common:messages.areYouSure'),
    text: i18n.t('common:messages.notBeRecover'),
};

/**
 * Wrapper component for sweetalert plugin
 */
const Swal = props => {

    const handleClick = e => {
        e.preventDefault();
        swal(props.options).then(p => props.callback(p, swal));
    };

    const { callback, ...rest } = props;
    return (
        <div {...rest} onClick={handleClick}>
            {props.children}
        </div>
    )
};

Swal.swal = async (options) => {
    return swal(options);
};

Swal.close = async () => {
    return swal.close();
};

Swal.propType = {
    /** swal options object */
    options: PropTypes.object.isRequired,
    /** callback function for swal response */
    callback: PropTypes.func
};

Swal.defaultProps = {
    callback: () => {}
};

export default Swal;
