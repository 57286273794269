import React, {Component} from "react";
import {Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {translate} from "react-i18next";

// import socketIOClient from "socket.io-client";
import API from '../../apiV2/auth';

import {getAuthUrl, getJwtToken} from "../../utils";

import {Form, SubmitField} from 'react-components-form';
import Schema from 'form-schema-validation';

import {TextField, SelectField} from "../Form";

import Tabs from '../Tabs/Tabs';

import CurrentUser from "../User/CurrentUser";

import {addMessage} from "../../store/actions";

import "./Authorization.scss";
import {setToken} from "../../apiV2/client";
import {ErrorMessages} from "../../schemas/ErrorMessages";

// const URL = process.env.REACT_APP_WS_HOST;
// let client = socketIOClient(URL);

const mapDispatchToProps = {addMessage};

const roles = [
    {
        label: '--- Select ---',
        value: '0'
    },
    {
        label: 'Manager',
        value: 'ROLE_MANAGER',
    },
    {
        label: 'Department manager',
        value: 'ROLE_DEPARTMENT_MANAGER',
    },
    {
        label: 'Department employee',
        value: 'ROLE_DEPARTMENT_EMPLOYEE',
    },
    {
        label: 'Citizen',
        value: 'ROLE_CITIZEN',
    },
    {
        label: 'Partner',
        value: 'ROLE_PARTNER',
    },
    {
        label: 'Employee',
        value: 'ROLE_EMPLOYEE',
    }
];

const schemaRole = new Schema({
    role: {
        type: String,
        required: true
    },
    user: {
        type: String,
        required: true
    }
}, ErrorMessages);

export class ConnectedAuthorization extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
            showIdCard: true,
            showMobileId: false,
            isLogged: false,
            loadingUsers: false,
            role: "0",
            users: []
        };

        this.logoutHandler = this.logoutHandler.bind(this);
    }

    componentDidMount() {
        this.logoutHandler = this.logoutHandler.bind(this);

        if (localStorage.getItem("user_token") !== null) {
            // client = socketIOClient(URL, {query: {token: localStorage.getItem("user_token")}});
            // client.connect();
            let token = getJwtToken();
            if(token && token.exp <= Math.round(+new Date()/1000)){
                this.logoutHandler();
            }else {
                this.setState({
                    isLogged: true
                });

                // Check token expired at
                /* istanbul ignore next */
                this.listener = setInterval(
                    () => {
                        let time = new Date().getTime();
                        let token = getJwtToken();
                        // let active = document.hasFocus();
                        //3600 - half of token_ttl (so, if half of time past, then try to refresh jwt token)
                        if (!token || token.exp <= parseInt(time / 1000) + 3600) {
                            // if (active) {
                            //     API.refresh(localStorage.getItem('refresh_token')).then(data => {
                            //         this.saveToken(data);
                            //     }).catch(error => {
                            //         if (error.response.status === 401) {
                            //             //JWT token expired. Stop refreshing
                            //             clearInterval(this.listener);
                            //         }
                            //     });
                            // }
                            // else {
                            //     localStorage.removeItem("user_token");
                            //     document.cookie = "BEARER=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                            //     this.props.history.push("/auth/continue");
                            // }
                        }

                        if (localStorage.getItem("user_token") === null) {
                            clearInterval(this.listener);
                        }
                    },
                    1000
                )
            }
        }

        // client.on("session", /* istanbul ignore next */ () => {
        //     // Send message
        //     this.props.addMessage({type: "error", message: "You logged into your account from other device!"});
        //
        //     localStorage.removeItem("user_token");
        //     document.cookie = "BEARER=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        //
        //     this.disconnectHandle();
        //     this.props.history.push("/");
        // });
    }

    /* istanbul ignore next */
    componentWillUnmount() {
        clearInterval(this.listener);
    }

    saveToken = /* istanbul ignore next */ (payload) => {
        document.cookie = "BEARER=" + payload.token + "; path=/;";
        localStorage.setItem("user_token", payload.token);
    };

    // Click login button
    loginHandler = (id) => {
        API.login(id).then(result => {
            if (result.token) {
                this.saveToken(result);
                this.connectHandle();

                setToken(result.token);

                this.setState({
                    isLogged: true,
                    role: this.state.role
                });
            }
        });
    }

    // Logout handler
    /* istanbul ignore next */
    logoutHandler() {
        // JWT Cookie need for file-storage server to validate permissions
        document.cookie = "BEARER=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        localStorage.removeItem("user_token");
        // localStorage.removeItem("refresh_token");
        // localStorage.removeItem("refresh_expired_at");
        localStorage.removeItem("permissions");
        localStorage.removeItem("role");
        localStorage.removeItem("lang");
        localStorage.removeItem("isRemoteLogin");
        localStorage.removeItem("redirect_after_login");

        this.setState({
            isLogged: false
        });

        clearInterval(this.listener);
        window.location.reload();
    }

    // Connect with web socket
    /* istanbul ignore next */
    connectHandle() {
        /* istanbul ignore next */
        // client = socketIOClient(URL, {query: {token: localStorage.getItem("user_token")}});
        // client.connect();
    }

    // Disconnect with web socket
    disconnectHandle() {
        /* istanbul ignore next */
        // client.disconnect();
        /* istanbul ignore next */
    }

    handleLogIn = () => {
        if(localStorage.getItem("user_token")){
            window.location.reload();
        } else{
            window.location = getAuthUrl();
        }
    }

    isSecuredLocation = /* istanbul ignore next */ (path) => {
        return this.props.location.pathname.substring(1, 14) === path;
    }

    handleChangeRole = (role) => {
        if (role !== "0") {
            this.setState({
                loadingUsers: true
            });

            API.getUsersByRole(role).then(users => {
                let options = [];

                options.push({
                    label: '--- Select ---',
                    value: '0'
                });

                for (let user of users) {
                    options.push({
                        label: user.first_name + ' ' + user.last_name + ' (' + user.identity_code + ')',
                        value: user.identity_code
                    });
                }

                this.setState({
                    users: options,
                    loadingUsers: false,
                    role: role,
                });
            });
        } else {

            this.setState({
                role: "0",
            });
        }
    }

    render() {
        const {t} = this.props;
        /* istanbul ignore next */
        if (this.state.isLogged && localStorage.getItem("user_token")) {

            return (
                <div className="logout">
                    {this.state.isLogged && (
                        this.state.role === "ROLE_MANAGER" && (
                            !this.isSecuredLocation("manage") && (
                                <Redirect push to="/manage"/>
                            )
                        )
                    )}
                    {this.state.isLogged && (
                        this.state.role === "ROLE_PARTNER" && (
                            !this.isSecuredLocation("partner") && (
                                <Redirect push to="/partner"/>
                            )
                        )
                    )}

                    {this.state.isLogged && (
                        this.state.role === "ROLE_EMPLOYEE" && (
                            !this.isSecuredLocation("employee") && (
                                <Redirect push to="/employee"/>
                            )
                        )
                    )}

                    {this.state.isLogged && (
                        this.state.role === "ROLE_DEPARTMENT_MANAGER" && (
                            !this.isSecuredLocation("department-manager") && (
                                <Redirect push to="/department-manager"/>
                            )
                        )
                    )}

                    {this.state.isLogged && (
                        this.state.role === "ROLE_DEPARTMENT_EMPLOYEE" && (
                            !this.isSecuredLocation("department-employee") && (
                                <Redirect push to="/department-employee"/>
                            )
                        )
                    )}

                    {this.state.isLogged && (
                        this.state.role === "ROLE_CITIZEN" && (
                            !this.isSecuredLocation("user") && (
                                <Redirect push to="/user"/>
                            )
                        )
                    )}

                    <CurrentUser/> &nbsp;

                    <a href="#/" onClick={this.logoutHandler}>
                        {t('authorization.logout')} &nbsp;
                        <em className="icon-logout"></em>
                    </a>
                </div>
            )
        }

        /* istanbul ignore next */
        if (!this.state.isLogged && localStorage.getItem("user_token") === null &&
            (this.props.location.pathname.startsWith('/manage') || this.props.location.pathname.startsWith('/user'))
        ) {
            return <Redirect push to="/"/>
        }

        let showLogout = false;
        if(this.props.location.pathname.startsWith('/auth')) {
            let searchParams = this.props.location.search.split(/[?&]/);
            showLogout = searchParams.some((val) => val.toString().startsWith('data='))

        }

        return (
            <div className="login">
                {this.state.showForm ? (
                    <div className="login-mask"></div>
                ) : ('')}

                { showLogout && (
                    <a href="#/" onClick={this.logoutHandler}>
                        {t('authorization.logout')} &nbsp;
                        <em className="icon-logout"></em>
                    </a>
                )}

                { !showLogout &&
                    <a className="login-link" href="#/" onClick={this.handleLogIn}>
                        <em className="icon-user"></em>
                        <span>{t('authorization.login')}</span>
                    </a>
                }

                {this.state.showForm ? (
                    <div className="login-form">
                        <Tabs>
                            <div title="ID Kaart">
                                <p>Sisesta ID-kaart kaardilugejasse ja vajuta nuppu "Sisenen"</p>

                                <Form
                                    schema={schemaRole}
                                    onSubmit={/* istanbul ignore next */ (model) => this.loginHandler(model.user)}
                                    onError={/* istanbul ignore next */ (error) => console.log(error)}
                                >

                                    <SelectField
                                        name="role"
                                        label={t('header.role')}
                                        options={roles}
                                        onChangeValue={this.handleChangeRole}
                                        value={roles[0].value}
                                        required={true}
                                    />
                                    {this.state.role !== "0" &&
                                    <SelectField
                                        name="user"
                                        label={t('authorization.loginAs')}
                                        options={this.state.users}
                                        value={0}
                                        required={true}
                                    />
                                    }

                                    <div className="text-center">
                                        <SubmitField className="btn btn-default" value={t('authorization.login')}/>
                                    </div>

                                </Form>
                            </div>
                            <div title="Mobile ID">
                                <p>Sisesta mobiiltelefoni number ja vajuta nuppu "Sisenen"</p>
                                <Form>
                                    <TextField
                                        label="Mobile number"
                                        name="phone"
                                        placeholder="56498####"
                                    />
                                    <button type="button"
                                            className="btn btn-default">
                                        {t('authorization.login')}
                                    </button>
                                </Form>
                            </div>
                        </Tabs>
                    </div>
                ) : ('')
                }
            </div>
        );
    }
}

const Authorization = connect(null, mapDispatchToProps)(ConnectedAuthorization);

export default withRouter(translate('common')(Authorization));
