import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { translate } from "react-i18next";
import logo from "../../../../images/logo-blue.svg";
import "./Header.scss"
import Authorization from "../../../../shared/Authorization/Authorization";
import LanguageSwitcher from "../../../../shared/LanguageSwitcher/LanguageSwitcher";
import settingsApi from "../../../../apiV2/settingsApi";
import { getJwtToken } from "../../../../utils";

const support_setting_name = 'support_email';

class Header extends Component {

    state = {
        showNav: false,
    };
    currentUser = getJwtToken();
    componentDidMount() {
        const supportEmail = localStorage.getItem(support_setting_name);
        this.setState({
            support_email: supportEmail,
        });
        settingsApi.getSystemSetting(support_setting_name).then(result => {
            if (result.data === null) result.data = '';
            else {
                this.setState({
                    support_email: result.data,
                });
                localStorage.setItem(support_setting_name, result.data);
            }
        });
    }

    toggleNavigation = () => {
        this.setState({
            showNav: !this.state.showNav,
        });
    };

    render() {
        /* istanbul ignore next */
        const { t } = this.props;

        return (
            <header className="sticky-top">
                <div className="pre-nav">
                    <div className="container">
                        <div className="row">
                            <div className="col text-right">
                                <div className="lang">
                                    <LanguageSwitcher />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <Link to="/" className="navbar-brand">
                            <img src={logo} width="120" className="d-inline-block align-top" alt="" />
                        </Link>

                        <button className="navbar-toggler collapsed"
                            onClick={this.toggleNavigation}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <em className="icon-menu"></em>
                        </button>

                        <div className={this.state.showNav ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'}
                            id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <NavLink to="/add"
                                        className="nav-link"
                                        activeClassName="active">
                                        {t("header.registerProblem")}
                                    </NavLink>
                                </li>
                                <NavLink to="/map"
                                    className="nav-link"
                                    activeClassName="active">
                                    {t("header.viewMap")}
                                </NavLink>
                            </ul>
                            {this.state.support_email && this.currentUser &&
                                <div className="abi">
                                    <span>Abi: <a href={"mailto:" + this.state.support_email}>{this.state.support_email}</a></span>
                                </div>}
                            <div className="auth">
                                <Authorization />
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default translate('common')(Header);