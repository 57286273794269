import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import pubsub from 'pubsub-js';

import ToggleState from '../Common/ToggleState';
import TriggerResize from '../Common/TriggerResize';
import ToggleFullscreen from '../Common/ToggleFullscreen';
import HeaderRun from './Header.run';

import logo from '../../../../images/logo.svg';
import logoIcon from '../../../../images/logo-icon.svg';

import { getJwtToken, getOS } from '../../../../utils';

import { withNamespaces } from "react-i18next";
import { setToken } from "../../../../apiV2/client";
import moment from "moment";
import Swal, { deleteOption } from "../../../../shared/Swal/Swal";
import { i18n } from "../../../../config";
import settingsApi from "../../../../apiV2/settingsApi";

const logoutOption = Object.assign({}, deleteOption);
logoutOption.title = i18n.t('common:messages.loggingOut');
logoutOption.text = '';
const support_setting_name = 'support_email';

class Header extends Component {
    state = {
        tooltipLogoutOpen: false,
        uniqueTab: null,
    };

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);

        window.onbeforeunload = function () {
            localStorage.removeItem('windowId');
        };
        this.isRemoteLogin = localStorage.getItem('isRemoteLogin');
    }

    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
            return v.toString(16);
        });
    };

    componentDidMount() {
        HeaderRun();

        let id = this.uuidv4();

        this.setState({
            uniqueTab: id,
        });

        localStorage.setItem('windowId', id);

        if (localStorage.getItem("user_token") !== null) {
            // client = socketIOClient(URL, {query: {token: localStorage.getItem("user_token")}});
            // client.connect();
            // client.connect();

            this.setState({
                isLogged: true
            });

            // Check token expired at
            /* istanbul ignore next */
            this.listener = setInterval(
                () => {
                    if (localStorage.getItem('windowId') === null) {
                        localStorage.setItem('windowId', this.state.uniqueTab);
                    }

                    if (this.state.uniqueTab === localStorage.getItem('windowId')) {
                        localStorage.setItem("currentTime", parseInt(localStorage.getItem("currentTime")) + 1);
                    }

                    this.checkToken();
                    if (localStorage.getItem("user_token") === null) {
                        clearInterval(this.listener);
                    }
                },
                1000
            );

            //Check allowed remote user using time
            if (this.isRemoteLogin === "true") {
                this.loginCheckInterval = setInterval(
                    () => {
                        if (moment(localStorage.getItem("remoteAccessEndLife")).isBefore(new Date())) {
                            document.cookie = "BEARER=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                            setToken('');
                            localStorage.removeItem("refresh_token");
                            localStorage.removeItem("refresh_expired_at");
                            localStorage.removeItem("user_token");
                            localStorage.removeItem("remoteAccessEndLife");
                            clearInterval(this.loginCheckInterval);

                            this.props.history.push("/");
                        }
                    },
                    1000
                );
            }
        }

        const supportEmail = localStorage.getItem(support_setting_name);
        this.setState({
            support_email: supportEmail,
        });
        settingsApi.getSystemSetting(support_setting_name).then(result => {
            if (result.data === null) result.data = '';
            else {
                this.setState({
                    support_email: result.data,
                });
                localStorage.setItem(support_setting_name, result.data);
            }
        });
    }

    checkToken = () => {
        let token = getJwtToken();
        let currentTime = localStorage.getItem("currentTime");

        if (!token || token.exp <= parseInt(currentTime)) {
            this.handleLogout();
        }
    };

    /* istanbul ignore next */
    componentWillUnmount() {
        clearInterval(this.listener);
        clearInterval(this.loginCheckInterval);
    }

    toggleUserblock = (e) => {
        e.preventDefault();
        pubsub.publish('toggleUserblock');
    };

    handleLogout() {
        if (['ios', 'android'].indexOf(getOS()) > -1) {
            Swal.swal(logoutOption).then(val => {
                if (val) {
                    this.logout();
                }
            });
        } else {
            this.logout();
        }
    }

    logout = () => {
        document.cookie = "BEARER=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

        localStorage.removeItem("user_token");
        localStorage.removeItem("permissions");
        localStorage.removeItem("isRemoteLogin");
        setToken('');
        clearInterval(this.listener);
        this.props.history.push('/');
    };

    saveToken = (payload) => {
        // JWT Cookie need for file-storage server to validate permissions
        document.cookie = "BEARER=" + payload.token + "; path=/;";
        localStorage.setItem("user_token", payload.token);
    };

    toggleLogoutTooltip = () => {
        this.setState({
            tooltipLogoutOpen: !this.state.tooltipLogoutOpen
        });
    };

    handleChangeRole = () => {
        const token = localStorage.getItem("user_token");
        this.props.history.push('/auth?id=1&changeRole=true&data=' + token);
    };

    render() {
        const token = getJwtToken();
        const { t } = this.props;

        return (
            <header className="topnavbar-wrapper">
                <nav className="navbar topnavbar">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src={logo} alt="Logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img src={logoIcon} height={30} alt="Logo" />
                            </div>
                        </a>
                    </div>
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            <TriggerResize>
                                <ToggleState state="aside-collapsed">
                                    <a href="#/" className="nav-link d-none d-md-block d-lg-block d-xl-block">
                                        <em className="fas fa-bars"></em>
                                    </a>
                                </ToggleState>
                            </TriggerResize>

                            <ToggleState state="aside-toggled" nopersist={true}>
                                <a href="#/" className="nav-link sidebar-toggle d-md-none">
                                    <em className="fas fa-bars"></em>
                                </a>
                            </ToggleState>
                        </li>

                        {token && (
                            <Fragment>
                                <li className="nav-item d-none d-md-block">
                                    <Link to={'/private/profile'} className="nav-link">
                                        <em className="icon-user"></em>
                                    </Link>
                                </li>
                                <li className={"nav-item user-info d-none d-md-block d-lg-block d-xl-block"}>
                                    <div className={"nav-link user-info"}>
                                        {token.currentRoleName && token.currentRoleName
                                            + ((token.currentCompany) ? (" (" + token.currentCompany.name + ")") : "")
                                            + ((token.currentDepartment) ? (" (" + token.currentDepartment.name + ")") : "")
                                        }
                                    </div>
                                </li>
                            </Fragment>
                        )}
                    </ul>

                    {this.state.support_email &&
                        <div className="privateabi">
                            <span>Abi: <a href={"mailto:" + this.state.support_email}>{this.state.support_email}</a></span>
                        </div>}

                    <ul className="navbar-nav flex-row">
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link" />
                        </li>

                        {/*If remote login -> disable role change to prevent unlimited session*/}
                        {this.isRemoteLogin !== "true" &&
                            <li className="nav-item">
                                <a href="#/" id="changeRole" className="nav-link" onClick={this.handleChangeRole}>
                                    <em className="fa fa-users"></em>
                                </a>
                                <Tooltip placement="bottom" isOpen={this.state.tooltipChangeRoleOpen} target="changeRole"
                                    toggle={() => {
                                        this.setState({
                                            tooltipChangeRoleOpen: !this.state.tooltipChangeRoleOpen
                                        });
                                    }}>
                                    {t('authorization.changeRole')}
                                </Tooltip>
                            </li>
                        }

                        <li className="nav-item">
                            <a href="#/" id="logout" className="nav-link" onClick={this.handleLogout}>
                                <em className="icon-logout"></em>
                            </a>
                            <Tooltip placement="bottom" isOpen={this.state.tooltipLogoutOpen} target="logout" toggle={this.toggleLogoutTooltip}>
                                {t('authorization.logout')}
                            </Tooltip>
                        </li>
                    </ul>

                    <form className="navbar-form" role="search" action="search.html">
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder={t('fields.typeAndEnter')} />
                            <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                    </form>
                </nav>
            </header>
        )
    }
}

export default withRouter(withNamespaces('common')(Header));
