import {client} from './client';

class Auth {
    static async getUsersByRole(role) {
        let result = await client.get('/users?role=' + role);
        return result.data;
    }

    static async loginRemoteUser(hash, role, signature, company, department) {
        role = typeof role === 'undefined' ? null : role;
        signature = typeof signature === 'undefined' ? null : signature;
        company = typeof company === 'undefined' ? null : company;
        department = typeof department === 'undefined' ? null : department;

        let result = await client.post(
            '/login', role ? {
                'hash': hash,
                'role': role,
                'signature': signature,
                'company': company,
                'department': department
            } : {'hash': hash}
        );

        return result.data;
    }

    static async login(token, role, signature, company, department) {
        role = typeof role === 'undefined' ? null : role;
        signature = typeof signature === 'undefined' ? null : signature;
        company = typeof company === 'undefined' ? null : company;
        department = typeof department === 'undefined' ? null : department;

        let result = await client.post(
            '/login', role ? {
                'token': token,
                'role': role,
                'signature': signature,
                'company': company,
                'department': department
            } : {'token': token}
        );

        return result.data;
    }

    static async refresh(token) {
        let result = await client.get('/refresh?refresh_token=' + token);
        return result.data;
    }

    static async refreshChangeRole(newRole, company,department) {
        let url = '/refresh?change_role=true&new_role=' + newRole;

        if (company) {
            url += '&company=' + company;
        }
        if (department) {
            url += '&department=' + department.toString();
        }
        let result = await client.get(url);

        return result.data;
    }

    static async getFssApiKey() {
        let result = await client.get('/fss_api_key');
        return result.data;
    }

    static async getTestFile() {
        let result = await client.get('/get_file');
        return result.data;
    }

    static async getPermissions(role) {
        let result = await client.post('/security/permissions', {role: role});
        return result.data;
    }

    static async getEmptyToken() {
        let result = await client.get('/auth/empty_token');
        return result.data;
    }

    static async taraAuth(taraCode) {
        let result = await client.post(
            '/auth/tara', {taraCode}
        );

        return result.data;
    }
}

export default Auth;
