/**
 * Parse JWT token
 * @param token
 * @returns {any}
 */
export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

/**
 * Get current JWT token
 * @returns {*}
 */
export const getJwtToken = () => {
    const token = localStorage.getItem('user_token');
    return (token) ? parseJwt(localStorage.getItem('user_token')) : null;
};

/**
 * Convert color from HEX format to rgba(red, green, blue, alpha)
 *
 * @param hex       Color in hex format (example: "#00FF00")
 * @param opacity   Opacity value, range from 0 to 1 (optional, by default 1)
 *
 * @return Array    Example: convertHex2Array("#00FF00", 0.5) => [0, 255, 0, 0.5]
 */
export function convertHex2Array(hex, opacity = 1) {
    hex = hex.replace('#','');
    let r = parseInt(hex.substring(0,2), 16),
        g = parseInt(hex.substring(2,4), 16),
        b = parseInt(hex.substring(4,6), 16);

    return [r, g, b, opacity];
}
export function convertRGBArrayToString(rgbArray) {
    if(rgbArray.length == 3) return `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
    if(rgbArray.length == 4) return `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, ${rgbArray[3]})`;
    return '';
}

/**
 * Generate array with numbers
 *
 * @param size      Array length (example: 5)
 * @param startAt   First number in range (optional, by default 0)
 *
 * @return Array    Example: range(5, 1) => [1, 2, 3, 4, 5]
 */
export function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}

/**
 * Serialize object to URL query string notation
 *
 * @param obj       Object to serialize (example: {foo: "bar", nested: {param: "value"}})
 * @param prefix    Prefix used to wrap passed object (prefix[param]=value) (optional, by default no prefix)
 *
 * @return String   Example: serialize({foo: "bar", nested: {param: "value"}}) => foo=bar&nested%5Bparam%5D=value
 */
export function serialize(obj, prefix = '') {
    let str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            if (!(Array.isArray(v) && v.length === 0))
                str.push((v !== null && typeof v === "object")
                    ? serialize(v, k)
                    : encodeURIComponent(k) + "=" + encodeURIComponent(v)
                );
        }
    }
    return str.join("&");
}

export function getOS(){
    let userAgent = navigator.userAgent.toLowerCase();
    let osName = "unknown";
    if (userAgent.indexOf("windows") !== -1) osName="windows";
    else if (/ipad|iphone|ipod/.test(userAgent)) osName="ios";
    else if (userAgent.indexOf("mac") !== -1) osName="mac";
    else if (userAgent.indexOf("x11") !== -1) osName="unix";
    else if (userAgent.indexOf("android") !== -1) osName="android";
    else if (userAgent.indexOf("linux") !== -1) osName="linux";

    return osName;
}

export function ucfirst(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lcfirst(string){
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function toUnderscore(string){
    return string.replace(/ /g,"_");
}

export function fromUnderscore(string){
    return string.replace(/_/g," ");
}

export function getAuthUrl(){
    return process.env.REACT_APP_AUTH_URL
        + "/authorize?scope=openid%20idcard%20mid%20smartid&response_type=code"
        + "&redirect_uri=" + encodeURI(process.env.REACT_APP_AUTH_URL_REDIRECT_URI)
        + "&state=" + process.env.REACT_APP_AUTH_URL_STATE
        + "&client_id=" + process.env.REACT_APP_AUTH_URL_CLIENT_ID
        + "&ui_locales=" + localStorage.getItem('lang');
}