import React, {Component} from 'react';

import CreatableSelect from 'react-select/lib/Creatable';
import {ErrorField, FieldConnect} from "react-components-form";
import {withNamespaces} from "react-i18next";

class CreatableSelectField extends Component {

    constructor(props) {
        super(props);

        this.state = {selectedOptions: props.selectedOptions};
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.selectedOptions){
            this.setState({selectedOptions: nextProps.selectedOptions});
        } else{
            this.setState({selectedOptions: nextProps.value});
        }
    }

    handleChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            this.props.createValue(newValue);
        }
        if (!newValue || newValue.length === 0){
            if(this.props.isMulti){
                this.setState({selectedOptions: []});
                this.props.onChange([]);
                if (this.props.onChangeValue) this.props.onChangeValue([]);
            } else{
                this.setState({selectedOptions: {}});
                this.props.onChange({});
                if (this.props.onChangeValue) this.props.onChangeValue({});
            }
        } else if (newValue) {
            this.props.onChange(newValue.value);
            if(typeof this.props.onChanged === 'function'){
                this.props.onChanged(newValue, actionMeta);
            }
            if (this.props.onChangeValue) this.props.onChangeValue(newValue);
        }
    };

    handleInput = (newValue, actionMeta) => {
        if(actionMeta.action === "input-change"){
            this.setState({selectedOptions: null});
        }
        if (this.props.onInputChange) this.props.onInputChange(newValue);
    };

    render() {
        const {
            label,
            name,
            hasValidationError,
            errorStyles,
            validationErrors,
            isLoading,
            disabled,
            required,
            options,
            placeholder,
            t,
            isClearable,
            isMulti,
            isCreatable,
            createLabel,
            noOptionsLabel
        } = this.props;

        return (
            <div className='form-group'>
                {label && <label htmlFor={name}>
                    {label}
                    {required && <span className="text-danger"> *</span>}
                </label>}
                <CreatableSelect
                    value={this.state.selectedOptions}
                    isClearable={isClearable}
                    onChange={this.handleChange}
                    onInputChange={(newValue, actionMeta) => {this.handleInput(newValue, actionMeta)}}
                    options={options}
                    placeholder={placeholder ? placeholder : t("fields.select")}
                    isLoading={isLoading}
                    isDisabled={disabled}
                    noOptionsMessage={() => {
                        return noOptionsLabel ? noOptionsLabel : t('fields.noOptions')
                    }}
                    formatCreateLabel={(inputValue) => {
                        return isCreatable === false
                            ? (t('fields.noOptions'))
                            : ((createLabel
                                ? createLabel
                                : t('grid.create')) + ': ' + inputValue);
                    }}
                    isMulti={isMulti}
                />
                {hasValidationError &&
                <small className="text-form text-danger">
                    <ErrorField errors={validationErrors} {...errorStyles} />
                </small>}
            </div>
        );
    }
}

export default FieldConnect(withNamespaces('common')(CreatableSelectField));
