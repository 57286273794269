import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Tab extends Component {

    onClick = () => {
        const {label, onClick} = this.props;
        onClick(label);
    };

    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
            },
        } = this;

        let className = 'tab__item';

        if (activeTab === label) {
            className += ' is-selected';
        }

        return (
            <li
                className={className}
                onClick={onClick}
            >
                <a href="#/">{label}</a>
            </li>
        )
    }
}

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};