import React, {Component} from 'react';
import {i18n} from "../../config";

import "./LanguageSwitcher.scss";

class LanguageSwitcher extends Component {

    state = {
        lang: "",
    };
    componentDidMount() {

        const lang = localStorage.getItem('lang');
        if(lang == 'en') localStorage.setItem('lang', 'et');
        if (lang !== null) {
            i18n.changeLanguage(lang);
            this.setState({
                lang: lang
            });
        } else {
            localStorage.setItem('lang', 'et');

            this.setState({
                lang: 'et'
            });
        }
    }

    handleOnChange = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);

        this.setState({
            lang: lang
        });
    }

    render() {
        return (
            <ul className="language-switcher">
                {/* <li className={this.state.lang === 'en' ? 'active' : ''}
                    onClick={() => {
                        this.handleOnChange('en')
                    }}>
                    EN
                </li>
                <li className={this.state.lang === 'et' ? 'active' : ''}
                    onClick={() => {
                        this.handleOnChange('et')
                    }}>
                    ET
                </li> */}
            </ul>
        );
    }
}

export default LanguageSwitcher;