import React, {Component} from "react";
import {Link} from "react-router-dom";

import {getJwtToken} from '../../utils';

import './CurrentUser.scss';

export default class CurrentUser extends Component {

    render() {
        const token = getJwtToken();

        if (token) {
            return (<div className="current-user">
                <Link to={"/private/profile"}>
                    {token.person_first_name} {token.person_last_name}
                </Link>
            </div>);
        } else {
            return <b>Please login</b>;
        }
    }
}
