import React from 'react';
import Async from 'react-code-splitting';
import TaraCallback from "../shared/Authorization/TaraCallback";

// Public components
const Public = () => <Async load={import('../modules/Public/Public')}/>;

// Private components
const Admin = () => <Async load={import('../modules/Base')}/>;

export const publicRoutes = [
    {
        title: 'Main',
        path: '/',
        component: Public
    },
    {
        title: 'Add a new problem',
        path: '/add',
        component: Public
    },
    {
        title: 'View problem',
        path: '/mv/:uid',
        component: Public
    },
    {
        title: 'Authorization',
        path: '/auth',
        component: Public
    },
    {
        title: 'TaRa authorization callback page',
        path: '/auth/tara_callback',
        component: TaraCallback
    },
    {
        title: 'Tenders',
        path: '/tenders',
        component: Public
    },
    {
        title: 'View tender',
        path: '/tenders/:id',
        component: Public
    },
    {
        title: 'Map',
        path: '/map',
        component: Public
    },
    {
        title: 'View problem',
        path: '/problems/:id',
        component: Public
    },
    {
        title: 'View object',
        path: '/objects/:id',
        component: Public
    },
    {
        title: 'Article',
        path: '/cookie_policy',
        component: Public
    }
];

export const routes = [
    {
        title: 'Manager',
        path: '/private*',
        component: Admin
    },
];
