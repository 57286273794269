import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ErrorField, FieldConnect } from 'react-components-form';
import { fieldDefaultProps, fieldDefaultPropTypes } from "../constants";
import './Textfield.scss';

export class TextField extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleChange({ target: { value } }) {
        const { trim, onChange } = this.props;

        onChange(trim && value ? value.trim() : value);

        if (typeof this.props.onChanged === "function") {
            this.props.onChanged(value);
        }
    }

    handleKeyDown = (e) => {
        if (typeof this.props.onKeyDown === "function") {
            this.props.onKeyDown(e);
        }
    };

    render() {
        const {
            name,
            type,
            required,
            validationErrors,
            hasValidationError,
            value,
            label,
            placeholder,
            errorStyles,
            fieldAttributes,
            disabled,
            min,
            max,
            step,
            labelAttributes,
            className
        } = this.props;

        const Wrapper = this.props.inputWrapper;

        if (!fieldAttributes.hasOwnProperty('autoComplete')) {
            fieldAttributes['autoComplete'] = 'off';
        }

        let inputClassName = hasValidationError ? 'form-control is-invalid' : 'form-control';
        inputClassName = (className && className.input) ? inputClassName + ' ' + className.input : inputClassName;

        let formGroupClassName = (type === 'hidden') ? 'form-group d-none' : 'form-group';
        formGroupClassName = (className && className.formGroup) ? formGroupClassName + ' ' + className.formGroup : formGroupClassName;

        return (
            <div className={formGroupClassName}>
                {(type === 'hidden') ? ('') : (
                    label && (
                        <label htmlFor={name} {...labelAttributes}>
                            {label}
                            {required && <span className="text-danger"> *</span>}
                        </label>
                    )
                )}
                <Wrapper>
                    <input
                        type={type === String ? 'text' : type}
                        name={name}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        value={value}
                        min={min}
                        step={step ? step : "any"}
                        max={max}
                        placeholder={placeholder}
                        className={inputClassName}
                        disabled={disabled ? 'disabled' : ''}
                        {...fieldAttributes}
                    />
                </Wrapper>
                {hasValidationError &&
                    <small className="text-form text-danger">
                        <ErrorField errors={validationErrors} {...errorStyles} />
                    </small>}
            </div>
        );
    }
}

TextField.propTypes = {
    ...fieldDefaultPropTypes,
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.func,
        PropTypes.object,
        PropTypes.array,
    ]),
    trim: PropTypes.bool,
    wrapper: PropTypes.object
};

TextField.defaultProps = {
    ...fieldDefaultProps,
    className: 'form-control',
    type: 'text',
    trim: false,
    inputWrapper: Fragment
};

export default FieldConnect(TextField);
