import React, {Component} from 'react';
import ReactGA from 'react-ga';

import logo from '../../../../images/logo-icon-blue.svg';
import elogo from '../../../../images/el.svg';

import "./Footer.scss"

class Footer extends Component {

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.initialize(process.env.REACT_APP_GA);
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <footer id="footer" className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5 text-center text-md-left">
                            <img className="footer-logo" src={logo} alt=""/>
                            <div className="footer-copyright">
                                Kõik õigused kaitstud © {new Date().getFullYear()} Tallinn
                            </div>
                        </div>
                        <div className="col-12 col-md-4 text-center text-md-right">
                            <nav>
                                <ul>
                                    <li>
                                        <a href="/opendata/">
                                            Avaandmed
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-12 col-md-3 text-center">
                            <img className="footer-el-logo" src={elogo} alt=""/>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
