import React, {Component, Fragment} from 'react';

import {fieldDefaultProps, fieldDefaultPropTypes} from '../constants';
import PropTypes from 'prop-types';
import {ErrorField, FieldConnect} from 'react-components-form';

import Dropzone from 'dropzone';

import 'dropzone/dist/basic.css';
import 'dropzone/dist/dropzone.css';
import './DropzoneField.scss';

import api from '../../../apiV2/fileSimpleStorageApi';
import * as i18n from "i18next";

const FSS_URL = process.env.REACT_APP_FSS_URL;

class DropzoneField extends Component {

    constructor(props) {
        super(props);

        this.dropZone = null;
    }

    state = {
        files: []
    };


    componentDidMount() {
        let access = this.props.private ? 'private' : 'public';
        const {onChange, mimeTypes, maxSize, maxFiles, id} = this.props;
        const self = this;

        this.dropZone = new Dropzone(id ? '#' + id : '#dropzone', {
            url: FSS_URL + "/chunk_upload",
            withCredentials: true,
            addRemoveLinks: true,
            maxFiles: maxFiles ? maxFiles : 10,
            maxFilesize: maxSize,
            dictDefaultMessage: this.props.defaultMessage ? this.props.defaultMessage : 'Drop files here to upload',
            dictRemoveFile: i18n.t('common:fields.deleteFile'),
            dictMaxFilesExceeded: i18n.t('common:fields.maxFiles'),
            dictInvalidFileType: i18n.t('common:fields.invalidFileType'),
            dictCancelUpload: i18n.t('common:fields.cancelUpload'),
            dictUploadCanceled: i18n.t('common:fields.uploadCanceled'),
            dictFileTooBig: i18n.t('common:fields.maxFileSize', {maxFilesize: maxSize}),
            dictCancelUploadConfirmation: i18n.t('common:messages.areYouSure'),
            acceptedFiles: mimeTypes ? mimeTypes.join(', ') : '',
            params: function (files, xhr, chunk) {
                if (chunk) {
                    return {
                        dzuuid: chunk.file.upload.uuid,
                        dzchunkindex: chunk.index,
                        dztotalfilesize: chunk.file.size,
                        dzcurrentchunksize: chunk.dataBlock.data.size,
                        dztotalchunkcount: chunk.file.upload.totalChunkCount,
                        dzchunkbyteoffset: chunk.index * this.options.chunkSize,
                        dzchunksize: this.options.chunkSize,
                        filename: chunk.file.name
                    };
                }
            },
            chunking: true,
            forceChunking: true,
            chunkSize: 2097152,
            parallelChunkUploads: 10,
            chunksUploaded: function (file, done) {
                api.done(file.upload.uuid, file.name, file.upload.totalChunkCount, file.size, access).then(result => {
                    let metadata = result.fileMetaInfo;
                    let replaceMetadata = {
                        latitude: metadata.latitude ? metadata.latitude : "",
                        longitude: metadata.longitude ? metadata.longitude : "",
                        createdDate: metadata.createdDate ? metadata.createdDate : "",
                        model: metadata.model ? metadata.model : "",
                    };

                    result.fileMetaInfo = replaceMetadata;

                    let files = self.state.files;
                    files.push(result);

                    self.setState({files});

                    onChange(files);
                    done();
                });
            },
            removedfile: function(file) {
                file.previewElement.parentNode.removeChild(file.previewElement);

                if (self.state.files.length > 0 && file.accepted) {
                    let files = self.state.files;
                    files.splice(files.findIndex(e => e.uuid === file.upload.uuid), 1);

                    self.setState({
                        files: files
                    });

                    onChange(files);
                    // api.delete(file.upload.uuid);
                }
            }
        });
    }

    componentWillUnmount() {
        this.dropZone = null;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.clean) {
            this.clean();
        }
    }

    clean = () => {
        this.setState({
            files: []
        });

        this.props.onChange([]);
        this.dropZone.removeAllFiles();
    };


    render() {
        const {label, required, hasValidationError, validationErrors, errorStyles, id, showFilename} = this.props;

        return (
            <Fragment>
                {label && <label htmlFor="dropzone">
                    {label}
                    {required && <span className="text-danger"> *</span>}
                </label>}
                <div
                    id={id !== undefined ? id : 'dropzone'}
                    className={"dropzone" + (showFilename ? " dz-show-filename" : "")}>
                </div>
                {hasValidationError &&
                <small className="text-form text-danger">
                    <ErrorField errors={validationErrors} {...errorStyles} />
                </small>}
            </Fragment>

        )
    }
}

DropzoneField.propTypes = {
    ...fieldDefaultProps,
    clean: PropTypes.any
};

DropzoneField.defaultProps = {
    ...fieldDefaultPropTypes,
    clean: PropTypes.any,
};

export default FieldConnect(DropzoneField);
