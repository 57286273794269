import React from 'react';
import PropTypes from 'prop-types';
import {getJwtToken} from "../utils";

class HasDepartment extends React.Component {
    render() {
        const allowedDepartment = this.props.allowedDepartment;
        const jwtToken = getJwtToken();
        let isDepartment = false;
        if (jwtToken !== null) {
            const userDepartment = jwtToken.currentDepartment;
            if(userDepartment && allowedDepartment) {
                isDepartment = allowedDepartment.some(r => userDepartment.name.indexOf(r) >= 0);
            } else
            {
                isDepartment = true;
            }
        }
        return (
            <>
                {isDepartment ? (this.props.children) : (this.props.accessDenied ? this.props.accessDenied : (
                        <div></div>
                    )

                )}
            </>
        )
    }
}

HasDepartment.propTypes = {
    allowedDepartment: PropTypes.array,
    accessDenied: PropTypes.any
};

HasDepartment.defaultProps = {
    accessDenied: null
};

export default HasDepartment;
