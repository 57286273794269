import {client} from './client';

class settingsApi {
    static async getUserSetting(key) {
        return await client.get('/settings/get_setting', {
            params: {
                'key': key,
            }
        });
    }

    static async setUserSetting(key, value) {
        return await client.post('/settings/set_setting', {
            'key': key,
            'value': value
        });
    }

    static async getSystemSetting(key) {
        return await client.get('/settings/get_system_setting', {
            params: {
                'key': key,
            }
        });
    }

    static async setSystemSetting(key, value) {
        return await client.post('/settings/set_system_setting', {
            'key': key,
            'value': value
        });
    }
}

export default settingsApi;
