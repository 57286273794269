import {client} from './client';

class systemMessageApi {
    static async search(url) {
        let resp = await client.get('/system_messages/search?url=' + url);
        return resp.data;
    }
}

export default systemMessageApi;
