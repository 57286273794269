import React, {Component} from 'react';
import {i18n} from '../../config';

import Header from './components/Header/Header';
import Sidebar from "./components/Sidebar/Sidebar";

import './PrivateLayout.scss';
import '../../styles/datagrid.scss';

import UpdateNotificator from '../../shared/UpdateNotificator/UpdateNotificator';
import AuthChecker from "./components/AuthChecker/AuthChecker";
import Footer from "./components/Footer/Footer";

export default class PrivateLayout extends Component {
    componentDidMount() {
        const lang = localStorage.getItem('lang');
        if(lang == 'en') localStorage.setItem('lang', 'et');
        if (lang !== null) {
            i18n.changeLanguage(lang);
            this.setState({
                lang: lang
            });
        } else {
            localStorage.setItem('lang', 'et');

            this.setState({
                lang: 'et'
            });
        }
    }

    render() {
        const Component = this.props.component;
        const route = this.props.route;

        return (
            <AuthChecker>
                <UpdateNotificator/>
                <div className="wrapper">
                    <Header/>
                    <Sidebar/>
                    <section className="section-container">
                        <Component route={route}/>
                    </section>
                    <Footer/>
                </div>

            </AuthChecker>
        )
    }
}
