// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./loader.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position-x: 0;\n  background-position-y: 0;\n  width: 23px;\n  height: 23px;\n  display: inline-block;\n  vertical-align: text-bottom; }\n", "",{"version":3,"sources":["webpack://src/shared/Loader/Loader.scss"],"names":[],"mappings":"AAAA;EACE,mDAA+B;EAC/B,sBAAsB;EACtB,wBAAwB;EACxB,wBAAwB;EACxB,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,2BAA2B,EAAA","sourcesContent":[".loader {\n  background: url('./loader.svg');\n  background-size: cover;\n  background-position-x: 0;\n  background-position-y: 0;\n  width: 23px;\n  height: 23px;\n  display: inline-block;\n  vertical-align: text-bottom;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
