import React, { Component } from "react";
import { connect } from "react-redux";
import "./Notification.scss";

import { addMessage, removeMessage } from "../../store/actions";

const mapStateToProps = state => state;

const mapDispatchToProps = {
    addMessage,
    removeMessage
};

export class MessageList extends Component {

    /* istanbul ignore next */
    constructor(props) {
        super(props);

        this.handleClearMessages = this.handleClearMessages.bind(this);

        this.state = {
            className: ""
        }
    }

    /* istanbul ignore next */
    componentWillReceiveProps(nextProps) {
        if (nextProps.notification.messages.length > 0) {
            setTimeout(this.handleClearMessages,
                nextProps.notification.messages[0].time ? nextProps.notification.messages[0].time : 7000);
            if (nextProps.notification.messages[0].className) {
                this.setState({
                    className: nextProps.notification.messages[0].className
                });
            }
        }
    }

    handleClearMessages = /* istanbul ignore next */ () => {
        this.props.removeMessage();

        this.setState({
            messages: this.props.messages
        });
    };

    handleClick = () => {
        this.handleClearMessages();
    };

    handleNotificationType = /* istanbul ignore next */ (key, type, message) => {
        return (
            <li onClick={this.handleClick} key={key}>
                {(() => {
                    switch (type) {
                        case 'info':
                            return (
                                <div className="alert alert-info" role="alert">
                                    {message}
                                </div>
                            );
                        case 'warning':
                            return (
                                <div className="alert alert-warning" role="alert">
                                    {message}
                                </div>
                            );
                        case 'error':
                            return (
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            );
                        case 'success':
                            return (
                                <div className="alert alert-success" role="alert">
                                    {message}
                                </div>
                            );
                        default:
                            return null;
                    }
                })()}
            </li>
        );
    };

    render() {
        return (
            <ul className={"notification container-fluid " + this.state.className}>
                {this.props.notification ? (
                    this.props.notification.messages.map((el, key) => (
                        this.handleNotificationType(key, el.type, el.message)
                    ))
                ) : ("")
                }
            </ul>
        );
    }
}

const Notification = connect(mapStateToProps, mapDispatchToProps)(MessageList);

export default Notification;