import React, {Fragment} from "react";
import {withNamespaces} from "react-i18next";

import Async from "react-code-splitting";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Notification from "./shared/Notification/Notification";
import Modal from 'react-modal';
// Api
import api from './apiV2/auth';
// Layouts
import PublicLayout from "./layouts/PublicLayout/PublicLayout";
import PrivateLayout from "./layouts/PrivateLayout/PrivateLayout";
// Routes
import {publicRoutes, routes} from './routes';

// Vendor dependencies
import "./vendor";
// Styles
import './styles/bootstrap.scss';
import './styles/app.scss';
import './App.scss';
import 'spinkit/css/spinkit.css';
import CookiePolicy from "./shared/CookiePolicy/CookiePolicy";
import systemMessageApi from "./apiV2/systemMessageApi";
import {connect} from "react-redux";
import {addMessage} from "./store/actions";
import moment from "moment";

// Set modal root element
document.getElementById('app') && Modal.setAppElement('#app');

const Continue = () => <Async load={import("./modules/Auth/Continue")}/>;
const mapDispatchToProps = /* istanbul ignore next */ dispatch => {
    return {
        addMessage: message => dispatch(addMessage(message))
    };
};

const FILES_HOST = process.env.REACT_APP_FILES_HOST;

function PageNotFoundMatch() {
    return <Redirect push to="/"/>;
}

class ConnectedApp extends React.Component {

    state = {
        file: null
    };
    componentDidMount() {
        api.getFssApiKey().then(result => {
            let cookieName = 'FSS-API-KEY';
            let cookieValue = result.key;
            let expiredDate = new Date();
            expiredDate.setMonth(expiredDate.getMonth() + 12);
            document.cookie = cookieName + "=" + cookieValue + ";expires=" + expiredDate
                + ";domain=" + FILES_HOST + ";path=/";
        });

        //IE fix
        if (!('remove' in Element.prototype)) {
            Element.prototype.remove = function () {
                if (this.parentNode) {
                    this.parentNode.removeChild(this);
                }
            };
        }

        systemMessageApi.search(window.location.pathname).then(resp => {
            if(resp.messages.length > 0){
                for (const msg of resp.messages){
                    this.props.addMessage({type: "warning",
                        message: <div dangerouslySetInnerHTML={{ __html: msg.text}} />,
                        className: msg.className,
                        time: moment(msg.endAt).diff(moment())
                    });
                }
            }
        });

        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        history.scrollRestoration = "manual"
    }

    render() {
        return (
            <Fragment>
                <CookiePolicy/>
                <Notification/>
                <Router>
                    <Fragment>
                        <Switch>
                            {publicRoutes.map(({path, component}) => (
                                <Route exact key={path} path={path}
                                       render={(path) =>
                                           <PublicLayout component={component} route={path}/>
                                       }
                                />
                            ))}

                            {routes.map(({path, component}) => (
                                <Route exact key={path} path={path}
                                       render={(path) =>
                                           <PrivateLayout component={component} route={path}/>
                                       }
                                />
                            ))}

                            {/* Custom pages */}
                            <Route path="/auth/continue" component={Continue}/>
                            <Route component={PageNotFoundMatch}/>
                        </Switch>
                    </Fragment>
                </Router>
            </Fragment>
        )
    }
}

const App = connect(null, mapDispatchToProps)(ConnectedApp);
export default withNamespaces('common')(App);
