import React from 'react';
import {withRouter} from "react-router-dom";

import Auth from "../../../../apiV2/auth";

class AuthChecker extends React.Component {

    state = {
        loading: true
    };

    componentDidMount() {
        Auth.getPermissions(localStorage.getItem('role')).then(result => {
            if (result) {
                localStorage.setItem('permissions', result.permissions);
                this.setState({
                    loading: false
                })
            }
        }).catch(error => {
            const {match: {params}} = this.props;
            if (params) {
                for (let prop in params) {
                    if (params.hasOwnProperty(prop)) {
                        if (/^\/(problems|objects)\/\d+$/.test(params[prop])) {
                            // if we try to open /private/problems/{id} or /private/objects/{id} page and authentication
                            // fails - redirect to public part of site (i.e. /problems/{id} or /objects/{id})
                            window.location.href = params[prop];
                        } else{
                            //Continue loading page and autologout by Header.sjx
                            this.setState({
                                loading: false
                            });
                        }
                    }
                }
            }
        });
    }

    render() {
        return (
            <>
                {this.state.loading === false &&
                <>{this.props.children}</>
                }
            </>

        )
    }
}

export default withRouter(AuthChecker);
