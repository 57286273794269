import React from 'react';
import PropTypes from 'prop-types';
import Storage from '../Storage/Storage';
import {FieldConnect} from 'react-components-form';

export class TableListField extends React.Component {
    static generateItemId() {
        return Math.random().toString(36).substring(7);
    }

    count = 0;

    constructor(props, {getSchema}) {
        super(props);
        this.state = {
            schema: getSchema(props.name),
            model: TableListField.getModelFromProps(props),
            validationErrors: {},
            total: 0,
        };

        this.storage = new Storage(this.state.model);
        this.setModel = this.setModel.bind(this);
        this.getModel = this.getModel.bind(this);
        this.getList = this.getList.bind(this);
        this.addListElement = this.addListElement.bind(this);
        this.removeListElement = this.removeListElement.bind(this);
        this.getSchema = this.getSchema.bind(this);
        this.getValidationErrors = this.getValidationErrors.bind(this);
        this.setStateModel = this.setStateModel.bind(this);
    }

    getChildContext() {
        return {
            setModel: this.setModel,
            getModel: this.getModel,
            getSchema: this.getSchema,
            getValidationErrors: this.getValidationErrors,
        };
    }

    componentDidMount() {
        this.storage.listen(this.setStateModel);
    }

    componentWillReceiveProps({value}) {
        let shouldSetState = false;

        value.forEach((item, key) => {
            if (this.state.model.length === 0 || item !== this.state.model[key].value) shouldSetState = true;
        });
        if (shouldSetState) this.storage.setModel(TableListField.getModelFromProps({value}));
    }

    componentWillUnmount() {
        this.storage.unlisten(this.setStateModel);
    }

    static getModelFromProps(props) {
        if (props.value.length > 0) {
            return props.value.map(item => ({
                id: TableListField.generateItemId(),
                value: item,
            }));
        }
        return [];
    }

    setStateModel(model, callback) {
        this.setState({model}, callback);
        this.props.onChange(model.map(item => item.value));
    }

    setModel(name, value, callback) {
        const key = name.split('-')[1];
        const model = Array.from(this.state.model);
        model[parseInt(key, 10)].value = value;
        this.storage.setModel(model, callback);
    }

    getModel(name) {
        const key = name.split('-')[1];
        return this.state.model[key].value;
    }

    getSchema() {
        return this.state.schema;
    }

    getValidationErrors(name) {
        const [fieldName, key] = name.split('-');
        const {getValidationErrors} = this.context;
        const validationErrors = getValidationErrors(fieldName);
        return validationErrors[parseInt(key, 10)] || [];
    }

    getDefaultValueForListItem() {
        if (
            this.state.schema &&
            this.state.schema.type &&
            this.state.schema.type[0] &&
            typeof this.state.schema.type[0] === 'object' &&
            typeof this.state.schema.type[0].getDefaultValues === 'function'
        ) {
            return this.state.schema.type[0].getDefaultValues();
        }
        return undefined;
    }

    getList(children) {
        const {
            name,
            removeButton: {
                wrapperClassName,
            },
            hideRemoveButton,
            itemWrapperClassName,
        } = this.props;

        const isRemoveAllowed = this.isRemoveAllowed();

        return this.state.model.map((item, key) => {
            const removeItemAction = (id) => this.removeListElement(key, id);
            const child = React.cloneElement(children, {
                name: `${name}-${key}`,
                index: key,
                value: item.value,
                key: item.id,
                removeListElement: removeItemAction,
            });

            return (
                <tr key={item.id} className={itemWrapperClassName}>
                    {child}
                    {!hideRemoveButton && isRemoveAllowed && <td className={wrapperClassName}>
                        <i style={{cursor: 'pointer'}} className="fas fa-trash-alt"
                           onClick={() => removeItemAction(item.value.id)}></i>
                    </td>}
                </tr>
            );
        });
    }

    isAddAllowed() {
        const {maxLength} = this.props;
        const {model} = this.state;
        if (typeof maxLength === 'number') return model.length < maxLength;
        return true;
    }

    isRemoveAllowed() {
        const {minLength} = this.props;
        const {model} = this.state;
        if (typeof minLength === 'number') return model.length > minLength;
        return true;
    }

    addListElement() {
        const model = Array.from(this.state.model);
        model.push({
            id: TableListField.generateItemId(),
            value: this.getDefaultValueForListItem(),
        });
        this.setState({model});
    }

    removeListElement(key, id) {
        const model = Array.from(this.state.model);
        model.splice(key, 1);
        this.setState({model});
        this.props.remove(id);
        this.props.onChange(model.map(item => item.value));
    }

    render() {
        const {
            children,
            cols,
            addButton,
            hideAddButton,
        } = this.props;

        const isAddAllowed = this.isAddAllowed();

        return (
            <>
                {this.getList(children)}
                {!hideAddButton && isAddAllowed &&
                <tr>
                    <td colSpan={cols}>
                        <button
                            type="button"
                            onClick={this.addListElement}
                            className={addButton.className}
                        >
                            {addButton.value || 'Add'}
                        </button>
                    </td>
                </tr>
                }
            </>
        );
    }
}

TableListField.contextTypes = {
    getSchema: PropTypes.func,
    getValidationErrors: PropTypes.func,
};

TableListField.childContextTypes = {
    setModel: PropTypes.func,
    getModel: PropTypes.func,
    getSchema: PropTypes.func,
    getValidationErrors: PropTypes.func,
};

TableListField.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    itemWrapperClassName: PropTypes.string,
    label: PropTypes.string,
    addButton: PropTypes.shape({
        className: PropTypes.string,
        value: PropTypes.node,
    }),
    removeButton: PropTypes.shape({
        wrapperClassName: PropTypes.string,
        className: PropTypes.string,
        value: PropTypes.node,
    }),
    hideAddButton: PropTypes.bool,
    hideRemoveButton: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.string,
            PropTypes.number,
        ]),
    ),
    fieldAttributes: PropTypes.shape({}),
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
};

TableListField.defaultProps = {
    children: '',
    className: '',
    wrapperClassName: '',
    itemWrapperClassName: '',
    label: '',
    addButton: {},
    removeButton: {},
    hideAddButton: false,
    hideRemoveButton: false,
    name: '',
    value: [],
    fieldAttributes: {},
    minLength: undefined,
    maxLength: undefined,
};

export default FieldConnect(TableListField);
