import React from 'react';

class OneOfGranted extends React.Component {

    handleParsePermissions = () => {
        let encoded = localStorage.getItem('permissions');
        return atob(encoded).split('|');
    };

    handleCheckPermission = (permissions) => {
        if (permissions === undefined) {
            return (<>{this.props.children}</>);
        }

        if (permissions) {
            for (let permission of permissions) {
                for (let valid of this.handleParsePermissions()) {
                    if (permission === valid) {
                        return (<>{this.props.children}</>);
                    }
                }
            }

            if (this.props.ifDenied) {
                return <>{this.props.ifDenied}</>;
            }

            return null;
        }
        return null;
    };

    render() {
        const {permissions} = this.props;
        return this.handleCheckPermission(permissions);
    }
}

export default OneOfGranted;
