import axios from "axios";

const FSS_URL = process.env.REACT_APP_FSS_URL;

const client = axios.create({
    baseURL: FSS_URL,
    timeout: 30000,
    withCredentials: true
});

class FileSimpleStorage {

    static async done(uid, name, totalChunkCount, size, access) {
        let data = new FormData();
        data.append('dzuuid', uid);
        data.append('filename', name);
        data.append('dztotalchunkcount', totalChunkCount);
        data.append('dztotalfilesize', size);
        data.append('access', access);

        let result = await client.post('/chunks_done', data);
        return result.data;
    }

    static async delete(uuid) {
        let result = await  client.delete('/delete/' + uuid);
        return result.data;
    }
}

export default FileSimpleStorage;