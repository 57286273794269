import React, {Component} from 'react';
import Header from './components/Header/Header';
import Footer from "./components/Footer/Footer";

export default class PublicLayout extends Component {
    render() {
        const RouteComponent = this.props.component;
        return (
            <>
                <div className="public-layout" id="publicLayout">
                    <Header/>
                    <div className="container-fluid">
                        <div className="row">
                            <RouteComponent {...this.props} />
                        </div>
                    </div>
                    <Footer/>
                </div>
            </>
        )
    }
}
