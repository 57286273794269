import i18n from "i18next";

import commonEn from "../translations/en/common.json";
import commonEt from "../translations/et/common.json";

i18n.init({
    fallbackLng: process.env.NODE_ENV === "test" ? 'en' : 'et',
    saveMissing: false,

    load: 'currentOnly', // you need to force this if you want fr-FR to be sent back first
    debug: false,

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: false,
        format: false,
    },
    react: {
        wait: true,
        nsMode: 'fallback',
    },
    resources: {
        en: {
            common: commonEn
        },
        et: {
            common: commonEt
        }
    },
});

export default i18n;