import {combineReducers} from "redux";

import messagesReducer from "./messages/messagesReducer";
import sidebarReducer from "./sidebar";

const rootReducer = combineReducers({
    notification: messagesReducer,
    sidebar: sidebarReducer
});

export default rootReducer;