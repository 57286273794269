import React from 'react';
import classnames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import { fieldDefaultProps, fieldDefaultPropTypes } from "../constants";
import { FieldConnect, ErrorField } from 'react-components-form';
import PropTypes from "prop-types";
import "./AsyncSelectField.scss";
import i18n from "i18next";
import Loader from '../../Loader/Loader';


export const defaultAsyncAttributes = {
    cacheOptions: true,
    defaultOptions: true,
};

class AsyncSelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            selectedOption: null
        }
    }

    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
        return inputValue;
    };

    handleOnChange(option) {
        /* istanbul ignore next */
        this.setState({ selectedOption: option });
        this.props.onChange(option.value);
    }

    loadOptions = (inputValue, callback) => {
        this.props.loadOptions(inputValue, (options) => {
            callback(options);
        });
    };

    setValue(optionValue) {
        if (optionValue) {
            this.loadOptions("", (options) => {
                this.setState({ selectedOption: options.find(option => option.value === optionValue) });
            });
        } else {
            this.setState({ selectedOption: null });
        }
    }

    noOptionsMessage = () => {
        if (this.state.inputValue === '') {
            return i18n.t('common:fields.enterKeyword');
        }
        return i18n.t('common:fields.noOptions');
    };

    componentDidMount() {
        this.setValue(this.props.value);
    }

    componentWillReceiveProps(nextProps) {
        if (null !== nextProps.selectedValue && this.props.selectedValue !== nextProps.selectedValue) {
            this.setState({
                selectedOption: nextProps.selectedValue
            });
        }
        else if (null === nextProps.selectedValue) {
            this.setValue(nextProps.value);
        }
    }

    render() {
        const {
            name,
            className,
            errorStyles,
            label,
            defaultValue,
            placeholder,
            isMulti,
            asyncAttributes,
            wrapperClassName,
            onChangeValue,
            hasValidationError,
            required,
            disabled,
            maxErrorsCount,
            loading
        } = this.props;

        let { validationErrors } = this.props;

        const fieldWrapperClassName = classnames(
            wrapperClassName,
            hasValidationError && errorStyles,
        );

        const mergedAsyncAttributes = Object.assign({}, defaultAsyncAttributes, asyncAttributes);

        //Replace validation error field name, bcs validator don't know field name
        if (validationErrors.length > 0) {
            for (let errorIdx in validationErrors) {
                validationErrors[errorIdx] = validationErrors[errorIdx].replace('{{}}', label);

                if (!maxErrorsCount) {
                    //Get only last error, bsc user don't need a lot of errors on same time and first error mb type error, if value is null
                    validationErrors = validationErrors[validationErrors.length - 1];
                    break;
                }
                //Remove odd errors
                else if (maxErrorsCount <= errorIdx) {
                    validationErrors.splice(errorIdx, 1);
                }
            }
        }

        return (
            <div className={fieldWrapperClassName}>
                {label && (
                    <label htmlFor={name}>
                        {loading && <Loader></Loader>}
                        {label}
                        {required && <span className="text-danger"> *</span>}
                    </label>
                )}
                <div className="async-select-wrp">
                    <AsyncSelect
                        className={className}
                        loadOptions={this.loadOptions}
                        placeholder={placeholder}
                        value={this.state.selectedOption ? this.state.selectedOption : defaultValue}
                        defaultValue={defaultValue}
                        isDisabled={disabled}
                        onChange={e => {
                            this.handleOnChange(e);
                            if (onChangeValue) onChangeValue(e);
                        }}
                        isMulti={isMulti}
                        onInputChange={this.handleInputChange}
                        noOptionsMessage={this.noOptionsMessage}
                        {...mergedAsyncAttributes}
                    />
                </div>
                {hasValidationError &&
                    <small className="text-form text-danger">
                        <ErrorField errors={validationErrors} {...errorStyles} />
                    </small>}
            </div>
        )
    }
}

AsyncSelectField.propTypes = {
    ...fieldDefaultPropTypes,
    loadOptions: PropTypes.func,
    asyncAttributes: PropTypes.shape({}),
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number
};

AsyncSelectField.defaultProps = {
    ...fieldDefaultProps,
    asyncAttributes: defaultAsyncAttributes,
    wrapperClassName: 'form-group',
    className: ''
};


export default FieldConnect(AsyncSelectField);