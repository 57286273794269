import React from 'react';
import {FieldConnect} from 'react-components-form';
import {fieldDefaultProps, fieldDefaultPropTypes} from "../constants";

export class TextField extends React.Component {

    render() {
        const {value} = this.props;

        return (
            <>
                {value}
            </>
        );
    }
}

TextField.propTypes = {
    ...fieldDefaultPropTypes
};

TextField.defaultProps = {
    ...fieldDefaultProps
};

export default FieldConnect(TextField);
