import React from 'react';

import './CookiePolicy.scss';

class CookiePolicy extends React.Component {

    state = {
        acceptedCookie: false
    };

    componentDidMount() {
        if (localStorage.getItem('acceptedCookie')) {
            this.setState({
                acceptedCookie: true
            });
        }
    }

    handleCheck = () => {
        localStorage.setItem('acceptedCookie', true);
        this.setState({
            acceptedCookie: true
        });
    };


    render() {
        if (!this.state.acceptedCookie) {
            return (
                <div className="container-fluid cookie-policy">
                    <div className="row">
                        <div className="col-12 col-sm-9 col-lg-10">
                            <div className="p-1">
                                Me kasutame küpsiseid statistika eesmärgil ja infosüsteemi funktsioonide võimaldamiseks.
                                Kasutades meie veebisaiti nõustute küpsiste kasutamisega eelpool mainitud tingimustel.
                                Tutvu
                                meie küpsiste kasutamise põhimõtetega <a href="/cookie_policy">küpsised</a>.
                            </div>
                        </div>
                        <div className="col-12 col-sm-3 col-lg-2 text-center text-sm-right">
                            <button className={'btn btn-info'} onClick={this.handleCheck}>
                                Nõustun
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }
}

export default CookiePolicy;
