import React from 'react';
import PropTypes from 'prop-types';

class CheckboxGroup extends React.Component {

    constructor() {
        super();
        this.getErrors = this.getErrors.bind(this);
    }

    getErrors() {
        const errors = this.context.getAllValidationErrors();
        const checkboxes = this.props.children;
        const result = [];

        if (Object.keys(errors).length > 0) {
            for (let error of Object.keys(errors)) {
                for (let checkbox of checkboxes) {
                    if (error === checkbox.props.name) {
                        result.push(
                            <div key={error} className="check-group__notice">{errors[error]}</div>
                        );
                    }
                }
            }
        }

        return (result)
    }

    render() {
        return (
            <div className={this.getErrors().length ? 'form-group is-error' : 'form-group'}>
                <div className="check-group__text">Roles</div>
                <div className="check-group__control">
                    {this.props.children}
                </div>
                {(this.props.required && this.getErrors().length === 0) ? (
                    <div className="check-group__notice">Selle välja täitmine on kohustuslik.</div>
                ) : (
                    <div/>
                )}

                {this.getErrors()}
            </div>
        )
    }
}

CheckboxGroup.contextTypes = {
    getSchema: PropTypes.func,
    getAllValidationErrors: PropTypes.func,
};

export default CheckboxGroup;