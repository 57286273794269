import React from 'react';
import PropTypes from 'prop-types';

import { FieldConnect, ErrorField } from 'react-components-form';

import './SelectField.scss';
import { i18n } from "../../../config";
import Loader from '../../Loader/Loader';

export const SelectField = ({
    wrapperClassName,
    className,
    onChange,
    name,
    validationErrors,
    hasValidationError,
    options,
    value,
    label,
    placeholder,
    errorStyles,
    fieldAttributes,
    required,
    onChangeValue,
    emptyOption,
    selectOption,
    loading
}) => (
    <div className={hasValidationError === true ? 'form-group' : 'form-group'}>
        {label && (
            <label htmlFor={name}>
                {loading && <Loader></Loader>}
                {label}
                {required && <span className="text-danger"> *</span>}
            </label>
        )}
        <select
            name={name}
            onChange={e => {
                onChange(e.target.value);
                if (onChangeValue) onChangeValue(e.target.value);
            }}
            value={value}
            placeholder={placeholder}
            className={hasValidationError ? 'form-control is-invalid' : 'form-control'}
            {...fieldAttributes}
        >
            {emptyOption &&
                <option
                    value={0}
                    key={0}
                    disabled={options.disabled}
                />
            }

            {selectOption &&
                <option
                    value={""}
                    key={0}
                    disabled={options.disabled}
                >
                    {i18n.t('common:fields.select')}
                </option>
            }

            {options.map(option => (
                <option
                    value={option.label ? option.value : option}
                    key={Math.random()}
                    disabled={option.disabled}
                >
                    {option.label || option}
                </option>
            ))}
        </select>

        {hasValidationError &&
            <small className="text-form text-danger">
                <ErrorField errors={validationErrors} {...errorStyles} />
            </small>}
    </div>
);

SelectField.propTypes = {
    options: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
    ])),
    onChangeValue: PropTypes.func
};

SelectField.defaultProps = {
    options: [],
};

export default FieldConnect(SelectField);
