import {SHOW_SIDEBAR, HIDE_SIDEBAR} from "../../constants/action-types";

const initialState = {
    sidebar: true
};

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SIDEBAR:
            return state = {
                sidebar: true
            };
        case HIDE_SIDEBAR:
            return state = {
                sidebar: false
            };
        default:
            return state;
    }
};

export default sidebarReducer;