import React from 'react';
import PropTypes from 'prop-types';
import {FieldConnect} from 'react-components-form';

import './CheckboxField.scss';

export class CheckboxField extends React.Component {
    /* istanbul ignore next */
    constructor(props) {
        super(props);
        let checked = typeof props.isChecked !== 'undefined' ? props.isChecked : props.value;
        this.state = {
            checked: checked,
            value: props.checkboxValue,
        };
        this.toggleValue = this.toggleValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /* istanbul ignore next */
    componentWillReceiveProps(nextProps) {
        let checked = typeof nextProps.isChecked !== 'undefined' ? nextProps.isChecked : nextProps.value;
        this.setState({checked: checked, value: nextProps.checkboxValue});
    }

    /* istanbul ignore next */
    getValue(checked) {
        const {type} = this.props;
        const {value} = this.state;
        if (type === Boolean) return !!checked;
        return checked ? value : undefined;
    }

    handleChange(obj) {
        if (typeof this.props.onCheck === "function") {
            this.props.onCheck(this.state.checked);
        }
    }

    /* istanbul ignore next */
    toggleValue() {
        const {onChange} = this.props;
        const {checked} = this.state;
        if(typeof onChange === 'function'){
            onChange(this.getValue(!checked));
        }
        this.setState({checked: !checked}, this.handleChange);
    }

    render() {
        const {
            path,
            label,
            placeholder,
            fieldAttributes,
            title,
            id,
            disabled
        } = this.props;


        return (
            <div
                title={title}
                className='checkbox c-checkbox'>
                <label htmlFor={id ? id : path}>
                    <input
                        type="checkbox"
                        checked={this.state.checked}
                        name={path}
                        disabled={disabled}
                        onChange={this.toggleValue}
                        placeholder={placeholder}
                        className="form-check-input"
                        id={id ? id : path}
                        {...fieldAttributes}
                    />
                    <span className="fa fa-check"></span>
                    {label ? ' ' + label : null}
                </label>
            </div>
        );
    }
}

CheckboxField.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.shape({}),
        PropTypes.func,
    ]),
    value: PropTypes.bool,
    checkboxValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.shape({}),
    ]),
};

CheckboxField.defaultProps = {
    type: Boolean,
    value: false,
    checkboxValue: true,
};

export default FieldConnect(CheckboxField);
